import { useCallback } from 'react';
import useWebSocket from 'react-use-websocket';

import {
  API_HOST,
  API_WEBSOCKET_HOST,
} from './constants';

export type UploadVoiceResponse = {
  result: 'success' | 'error',
  id?: string,
  message?: string,
};

export const uploadVoice = async (userId: string, corpusName: string, corpusID: Number, blobUrl: string) => {
  const voiceBlob = await fetch(blobUrl).then(r => r.blob());

  const formData = new FormData();
  formData.append('upload', voiceBlob, 'recorded_voice.wav');
  formData.append('user_id', userId);
  formData.append('corpus_name', corpusName);
  formData.append('corpus_id', corpusID.toString());

  const response = await fetch(`${API_HOST}/corpus_voices/upload`, {
    method: 'POST',
    body: formData,
    mode: 'cors',
  });
  const responseData: UploadVoiceResponse = await response.json();
  return responseData;
};

export type DownloadVoiceResponse = {
  success: boolean,
  blobUrl?: string,
  message?: string,
};

export const downloadVoice = async (voiceId: string): Promise<DownloadVoiceResponse> => {
  const response = await fetch(`${API_HOST}/voices/${voiceId}/`, {
    method: 'GET',
    mode: 'cors',
  });
  if (response.ok) {
    const responseBlob = await response.blob();
    const blobUrl = window.URL.createObjectURL(responseBlob);
    console.log('Converted Blob URL: ', blobUrl);
    return { success: true, blobUrl };
  } else {
    return { success: false, message: '変換音声のダウンロード失敗' };
  }
};

export type VoiceConversionWebSocketResponse = {
  id: string,
  voice_id: string,
  type: 'vcs_message',
  message: 'finish' | 'received' | 'voice_not_found',
};

export type UseVoiceConversionWebSocketProps = {
  onVoiceConversionDone: (message: VoiceConversionWebSocketResponse) => Promise<void>,
};

export const useVoiceConversionWebSocket = ({
  onVoiceConversionDone,
}: UseVoiceConversionWebSocketProps) => {
  const onMessage = useCallback((event: MessageEvent<any>) => {
    const message = JSON.parse(event.data) as VoiceConversionWebSocketResponse;
    onVoiceConversionDone(message);
  }, [onVoiceConversionDone]);

  const {
    sendJsonMessage,
    // readyState,
    // TODO: Wait for being ready
  } = useWebSocket(API_WEBSOCKET_HOST, { onMessage });

  const sendVoice = useCallback((voiceId: string, vcParams: string) => {
    sendJsonMessage({
      org_voice_id: voiceId,
      vc_params: vcParams,
    });
  }, [sendJsonMessage]);

  return { sendVoice }
};

export const getCorpusVoiceDic = async (userId: string, corpusName: string) => {
  const response = await fetch(`${API_HOST}/corpus_voices/${corpusName}/list/${userId}`, {
    method: 'GET',
    mode: 'cors',
  });
  const responseData: any = await response.json();
  return responseData;
};