import { Forward as ForwardIcon } from '@mui/icons-material';
import React from 'react';

import { AudioPlayer } from './AudioPlayer';
import { LoadingIndicator } from './LoadingIndicator';
import { Grid } from '@mui/material';

export type ConvertedVoiceType = {
  recordedAudioBlobUrl: string;
  recordedAt: Date;
  uploadedVoiceId?: string;
  conversionId?: string;
  convertedAudioBlobUrl?: string;
};

export const ConvertedVoiceTile = ({ recordedAudioBlobUrl, recordedAt, convertedAudioBlobUrl }: ConvertedVoiceType) => {
  return (
    <Grid container className="bg-gray-200 rounded-lg p-4 flex flex-col animate-fadein-once">
      <Grid item className="text-sm text-gray-700">
        <span className="inline-block mr-2 font-bold">録音日時</span>
        {recordedAt.toLocaleString()}
      </Grid>

      <Grid item className="flex space-x-6 mt-3">
        <div className="flex-1 text-center">
          <AudioPlayer blobUrl={recordedAudioBlobUrl} />
        </div>
        {/* <div className="flex-none self-center">
          <ForwardIcon sx={{ fontSize: 30 }} />
        </div> */}
        {/* <div className="flex-1 flex flex-col text-center">
          <p className="mb-2 text-lg">変換音声</p>
          {convertedAudioBlobUrl ?
            <AudioPlayer blobUrl={convertedAudioBlobUrl} />
            :
            <LoadingIndicator className="grow" loadingLabel="変換中..." />
          }
        </div> */}
      </Grid>
    </Grid>
  );
};
