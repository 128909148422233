import { useEffect, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';
// @ts-expect-error
import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor';

import { ButtonWithIcon } from './ButtonWithIcon';

export type AudioPlayerProps = {
  blobUrl: string;
};

export const AudioPlayer = ({ blobUrl }: AudioPlayerProps) => {
  const waveformRef = useRef<WaveSurfer | null>(null);

  useEffect(() => {
    if (waveformRef === null || waveformRef.current === null) return;

    waveformRef.current = WaveSurfer.create({
      height: 60,
      // @ts-ignore-error
      container: waveformRef.current,
      plugins: [
        CursorPlugin.create({
          showTime: true,
          opacity: 1,
          customShowTimeStyle: {
            'background-color': '#000',
            color: '#fff',
            padding: '2px',
            'font-size': '10px',
          },
        })
      ],
    });
    waveformRef.current.load(blobUrl);
  }, [blobUrl]);

  function handlePlayPause() {
    if ( waveformRef.current !== null)
      waveformRef.current.playPause();
  }

  return (
    <>
      {/* @ts-ignore-error */}
      {/* <div ref={waveformRef} /> */}
      <div className="flex justify-center mt-2">
        <ButtonWithIcon text="Play/Pause" onClick={handlePlayPause} />
      </div>
    </>
  );
};
