const netrab512Text = `- NETRAB00001
今日も一日 頑張りましょう
キョーモイチニチ ガンバリマショウ
- NETRAB00002
どうぞよろしくお願いします
ドウゾヨロシクオネガイシマス
- NETRAB00003
今年一年間 お疲れ様でした
コトシイチネンカン オツカレサマデシタ
- NETRAB00004
またの機会を お待ちしております
マタノキカイオ オマチシテオリマス
- NETRAB00005
明けまして おめでとうございます
アケマシテ オメデトウゴザイマス
- NETRAB00006
心から お悔み申し上げます
ココロカラ オクヤミモウシアゲマス
- NETRAB00007
末永く お幸せになってください
スエナガク オシアワセニナッテクダサイ
- NETRAB00008
本日はお招きを頂きまして 誠にありがとうございます
ホンジツワオマネキオイタダキマシテ マコトニアリガトウゴザイマス
- NETRAB00009
大変お世話になりました
タイヘンオセワニナリマシタ
- NETRAB00010
いつもお世話になっております
イツモオセワニナッテオリマス
- NETRAB00011
この土地は 八百年も 奇妙な霧に覆われていた
コノトチワ ハッピャクネンモ キミョオナキリニオオワレテイタ
- NETRAB00012
牛乳は 水よりも 早く沸騰する
ギューニュウワ ミズヨリモ ハヤクフットースル
- NETRAB00013
デビュー作品が 不発に終わってしまう
デビューサクヒンガ フハツニオワッテシマウ
- NETRAB00014
アヒルの鳴き声は グヮグヮと 表現される
アヒルノナキゴエワ グヮグヮト ヒョオゲンサレル
- NETRAB00015
参考文献から 新しい論文を 引っ張ってくる
サンコオブンケンカラ アタラシイロンブンオ ヒッパッテクル
- NETRAB00016
資格を得るには 中学校を 卒業しなければいけない
シカクオエルニワ チュウガッコオオ ソツギョウシナケレバイケナイ
- NETRAB00017
買い物へ出掛けるには まだ早い時間帯であった
カイモノエデカケルニワ マダハヤイジカンタイデアッタ
- NETRAB00018
テュールは シェール川とロワール川の間に 位置する
テュールワ シェールガワトロワールガワノアイダニ イチスル
- NETRAB00019
配線が ショートを起こし ヒューズが飛んだ
ハイセンガ ショートオオコシ ヒューズガトンダ
- NETRAB00020
この三十秒間が 成功するかどうかの 瀬戸際だ
コノサンジュービョーカンガ セーコースルカドーカノ セトギワダ
- NETRAB00021
世界的なヴァイオリニストを 招待する
セカイテキナヴァイオリニストオ ショータイスル
- NETRAB00022
今年一年を ひと文字で表現する
コトシイチネンヲ ヒトモジデヒョーゲンスル
- NETRAB00023
みんなで ごみ置き場を 掃除しに行く
ミンナデ ゴミオキバオ ソージシニイク
- NETRAB00024
社長を 至急呼び出す事態に なってしまった
シャチョーオ シキューヨビダスジタイニ ナッテシマッタ
- NETRAB00025
使う道具は ペンではなくて 万年筆であった
ツカウドーグワ ペンデワナク マンネンヒツデアッタ
- NETRAB00026
ふらっと 寄り道もせずに まっすぐ帰ってくる
 フラット ヨリミチモセズニ マッスグカエッテクル
- NETRAB00027
名簿を確認して 部屋への入室を許可する
メーボオカクニンシテ ヘヤエノニューシツオキョカスル
- NETRAB00028
フューチャーワークに 詳細な実験を 行う予定だ
フューチャーワークニ ショーサイナジッケンオ オコナウヨテーダ
- NETRAB00029
シークヮーサーの カクテルを 準備する
シークヮーサーノ カクテルオ ジュンビスル
- NETRAB00030
自然環境を保つために リデュースを徹底する
シゼンカンキョーオタモツタメニ リデュースオテッテースル
- NETRAB00031
ニュートンは 万有引力を 発見した
ニュートンワ バンユーインリョクオ ハッケンシタ
- NETRAB00032
お餅がとても柔らかく まんじゅうにはふさわしい
オモチガトテモヤワラカク マンジューニワフサワシー
- NETRAB00033
審査員の表情からして 合格の脈はある
シンサインノヒョージョーカラシテ ゴーカクノミャクワアル
- NETRAB00034
銀の銃弾は ヴァンパイアに とても有効だ
ギンノジューダンワ ヴァンパイアニ トテモユーコーダ
- NETRAB00035
自分の名字は 佐藤であると申し上げた
ジブンノミョージワ サトーデアルトモーシアゲタ
- NETRAB00036
麦畑の中に 一輪のヒマワリが 咲いていた
ムギバタケノナカニ イチリンノヒマワリガ サイテイタ
- NETRAB00037
果てしない大空を しばらくの間 見上げていた
ハテシナイオーゾラオ シバラクノアイダ ミアゲテイタ
- NETRAB00038
無茶な命令に 兵隊たちも うんざりだった
ムチャナメーレーニ ヘータイタチモ ウンザリダッタ
- NETRAB00039
卑怯な方法で勝っても 嬉しくない
ヒキョーナホーホーデカッテモ ウレシクナイ
- NETRAB00040
みんなで集まって パーティーを開催した
ミンナデアツマッテ パーティーオカイサイシタ
- NETRAB00041
不平等なテストは あってはならない
フビョードーナテストワ アッテワナラナイ
- NETRAB00042
デートに お気に入りのパフュームを使う
デートニ オキニイリノパフュームオツカウ
- NETRAB00043
プロデューサーに 出演依頼を お願いする
プロデューサーニ シュツエンイライオ オネガイスル
- NETRAB00044
複雑な気持ちは 簡単に 晴れるものではない
フクザツナキモチワ カンタンニ ハレルモノデハナイ
- NETRAB00045
二十分以内につけば 報酬を追加する
ニジュップンイナイニツケバ ホーシューオツカイスル
- NETRAB00046
ウサギが ぴょんぴょんと 飛びながら近づく
ウサギガ ピョンピョント トビナガラチカズク
- NETRAB00047
無表情のまま 雑煮を食べまくった
ムヒョージョーノママ ゾーニオタベマクッタ
- NETRAB00048
今晩は ビーフシチューを 彼女と食べる
コンバンワ ビーフシチューオ カノジョトタベル
- NETRAB00049
塩とレモンを加え 牛乳を混ぜて飲む
シオトレモンオクワエ ギューニューオマゼテノム
- NETRAB00050
百科事典に 新しい項目が 追加された
ヒャッカジテンニ アタラシーコーモクガ ツイカサレタ
- NETRAB00051
空腹が 何日も続いて 暴れる気力もない
クーフクガ ナンニチモツズイテ アバレルキリョクモナイ
- NETRAB00052
まずは 一つ目に書かれた 呪文を読み上げる
マズワ ヒトツメニカカレタ ジュモンオヨミアゲル
- NETRAB00053
会社から 有給休暇を消費するように 頼まれた
カイシャカラ ユーキューキューカオショーヒスルヨーニ タノマレタ
- NETRAB00054
おばあちゃんに お茶を一杯 いれてあげる
オバーチャンニ オチャオイッパイ イレテアゲル
- NETRAB00055
ミュージシャンたちが こぞって ステージにあがった
ミュージシャンタチガ コゾッテ ステージニアガッタ
- NETRAB00056
価格は三百ドルで 取引の提案をした
カカクワサンビャクドルデ トリヒキノテーアンオシタ
- NETRAB00057
大量の写真フィルムを つなぎ合わせる
タイリョーノシャシンフィルムオ ツナギアワセル
- NETRAB00058
実績を みんなの前で発表することが 愉快だった
ジッセキオ ミンナノマエデハッピョースルコトガ ユカイダッタ
- NETRAB00059
ニュースを確認する 習慣をつける
ニュースオカクニンスル シューカンオツケル
- NETRAB00060
久しぶりに 一家全員が リビングに集まった
ヒサシブリニ イッカゼーインガ リビングニアツマッタ
- NETRAB00061
データの受信に失敗し 一部が消失した
データノジュシンニシッパイシ イチブガショーシツシタ
- NETRAB00062
珍しい昆虫と 植物が ぼんやりと見える
メズラシー コンチュートショジュブツガ ボンヤリトミエル
- NETRAB00063
怪しい勧誘は きっぱりと断ることが 大事である
アヤシーカンユーワ キッパリトコトワルコトガ ダイジデアル
- NETRAB00064
ゴリラを 絶滅から守る運動が 広がっている
ゴリラオ ゼツメツカラマモルウンドーガ ヒロガッテイル
- NETRAB00065
彼の占いは 百発百中で 外したことがない
カレノウラナイワ ヒャッパツヒャクチューデ ハズシタコトガナイ
- NETRAB00066
日本語は 述語を見つけるのが難しい
ニホンゴワ ジュツゴオミツケルノガムズカシー
- NETRAB00067
ペラペラと ページをめくっては あくびをする
ペラペラト ページオメクッテワ アクビオスル
- NETRAB00068
大学を卒業して 自分好みの職に就く
ダイガクオソツギョーシテ ジブンゴノミノショクニツク
- NETRAB00069
ヴェネツィアは イタリアで人気な 観光スポットだ
ヴェネツィアワ イタリアデニンキナ カンコースポットダ
- NETRAB00070
あと数分で 除夜の鐘が 鳴り響く
アトスーフンデ ジョヤノカネガ ナリヒビク
- NETRAB00071
もともと 競技に参加する予定は なかった
モトモト キョーギニサンカスルヨテーワ ナカッタ
- NETRAB00072
バリアフリーの観点から スロープを設置する
バリアフリーノカンテンカラ スロープオセッチスル
- NETRAB00073
ラーメン屋で ついつい餃子も 頼んでしまう
ラーメンヤデ ツイツイギョーザモ タノンデシマウ
- NETRAB00074
頭痛の回数も減って 症状は どんどん良くなる
ズツーノカイスーモヘッテ ショージョーワ ドンドンヨクナル
- NETRAB00075
騒ぎが次第に大きくなり 親の耳にも入る
サワギガシダイニオーキクナリ オヤノミミニモハイル
- NETRAB00076
タオルで汗をふき 部活動を終える
タオルデアセオフキ ブカツドーオオエル
- NETRAB00077
日焼けをして 両腕の皮膚が いっぱいめくれる
ヒヤケオシテ リョーウデノヒフガ イッパイメクレル
- NETRAB00078
善良な人間は どこの会社にも 一人は存在する
ゼンリョーナニンゲンワ ドコノカイシャニモ ヒトリワソンザイスル
- NETRAB00079
ボーヴォワールは 女性解放運動に 参加していた
ボーヴォワールワ ジョセーカイホーウンドーニ サンカシテイタ
- NETRAB00080
強い風が ヒューヒューと 吹いている
ツヨイカゼガ ヒューヒュート フイテイル
- NETRAB00081
コンピューターの進歩は とにかく著しい
コンピューターノシンポワ トニカクイチジルシー
- NETRAB00082
無器用な人でも 簡単に作ることができる
ブキヨーナヒトデモ カンタンニツクルコトガデキル
- NETRAB00083
白虎隊は 幕末に 会津藩が編成した
ビャッコタイワ バクマツニ アイズハンガヘンセーシタ
- NETRAB00084
ハネムーンは ニューヨークに決定する
ハネムーンワ ニューヨークニケッテースル
- NETRAB00085
鍛え抜かれたボディーを みんなの前で披露する
キタエヌカレタボディーオ ミンナノマエデヒロースル
- NETRAB00086
ミュージカルのチケットが 初めて当たった
ミュージカルノチケットガ ハジメテアタッタ
- NETRAB00087
まだ チェックシートの記入が 済んでいない
マダ チェックシートノキニューガ スンデイナイ
- NETRAB00088
おじけずに 本当のことをしゃべる
オジケズニ ホントーノコトオシャベル
- NETRAB00089
バナナは 生のままでも 調理をしても おいしい
バナナワ ナマノママデモ チョーリオシテモ オイシー
- NETRAB00090
モグラがひょっこりと 頭を出している
モグラガヒョッコリト アタマオダシテイル
- NETRAB00091
小さな漁船から 漁夫の姿が うかがえる
チーサナギョセンカラ ギョフノスガタガ ウカガエル
- NETRAB00092
客寄せに キャッチフレーズは 重要だ
キャクヨセニ キャッチフレーズワ ジューヨーダ
- NETRAB00093
コンピューターに ディスプレイはつきものだ
コンピューターニ ディスプレイワツキモノダ
- NETRAB00094
乗用車が 前方から 一台近づいてくる
ジョーヨーシャガ ゼンポーカラ イチダイチカズイテクル
- NETRAB00095
ヴィンテージ物を 販売しているお店に 足をはこぶ
ヴィンテージモノオ ハンバイシテイルオミセニ アシオハコブ
- NETRAB00096
ミャンマーと 良好な関係を 築きあげる
ミャンマート リョーコーナカンケーオ キズキアゲル
- NETRAB00097
ワッフルを 新作のメニューとして 追加する
ワッフルオ シンサクメニュートシテ ツイカスル
- NETRAB00098
カフェで コーヒーを飲みながら 作業をする
カフェデ コーヒーオノミナガラ サギョーオスル
- NETRAB00099
病室に クラスのみんなが お見舞いに来る
ビョーシツニ クラスノミンナガ オミマイニクル
- NETRAB00100
洗練された パフォーマンスは 観客を魅了する
センレンサレタ パフォーマンスワ カンキャクオミリョースル
- NETRAB00101
スピーカーから 咳払いをする音が 聞こえる
スピーカーカラ セキバライオスルオトガ キコエル
- NETRAB00102
使用方法の概略を 簡単に説明する
シヨーホーホーノガイリャクオ カンタンニセツメースル
- NETRAB00103
メキシコに伝わる 独特な音楽が 馴染んでくる
メキシコニツタワル ドクトクナオンガクガ ナジンデクル
- NETRAB00104
うまくいかなかったとしても 逆ギレは しちゃいけない
ウマクイカナカッタトシテモ ギャクギレダケワ シチャイケナイ
- NETRAB00105
シャンハイの街は まだまだ眠らない
シャンハイノマチワ マダマダネムラナイ
- NETRAB00106
うっすらと 自分の心を 覗かれたような気がした
ウッスラト ジブンノココロオ ノゾカレタヨーナキガシタ
- NETRAB00107
満員の観客が 主役の登場を じっと待っていた
マンインノカンキャクガ シュヤクノトージョーオ ジットマッテイタ
- NETRAB00108
採掘作業を かなり行ったが 何も得られなかった
サイクツサギョーオ カナリオコナッタガ ナニモエラレナカッタ
- NETRAB00109
かなり慎重に まぶたや脈を 調査する
カナリシンチョーニ マブタヤミャクオ チョーサスル
- NETRAB00110
サンゴ礁の 美しさを 守らなければいけない
サンゴショーノ ウツクシサオ マモラナケレバイケナイ
- NETRAB00111
牛肉は 鉄分が多く 貧血対策によい
ギューニクワ テツブンガオーク ヒンケツタイサクニヨイ
- NETRAB00112
自分の名字が 間違ったまま プリントに載る
ジブンノミョージガ マチガッタママ プリントニノル
- NETRAB00113
ジョギング中に ポケットから 鍵を落としてしまった
ジョギングチューニ ポケットカラ カギオオトシテシマッタ
- NETRAB00114
予想される最悪な状況は 回避している
ヨソーサレルサイアクナジョーキョーワ カイヒシテイル
- NETRAB00115
ピザの正しい発音は ピッツァである
ピザノタダシーハツオンワ ピッツァデアル
- NETRAB00116
海岸には ギザギザした絶壁が そびえたつ
カイガンニワ ギザギザシタゼッペキガ ソビエタツ
- NETRAB00117
かんぴょうやシイタケを 天日干しする
カンピョーヤシータケオ テンピボシスル
- NETRAB00118
玄関の彫刻は 何年たっても 立派だ
ゲンカンノチョーコクワ ナンネンタッテモ リッパダ
- NETRAB00119
巡礼中の女性は 嬉しそうに お辞儀をした
ジュンレーチューノジョセーワ ウレシソーニ オジギオシタ
- NETRAB00120
何時間にも及ぶ 博士の演説が 始まった
ナンジカンニモオヨブ ハカセノエンゼツガ ハジマッタ
- NETRAB00121
議論を中断させたのは ギャラリーだった
ギロンオチューダンサセタノワ ギャラリーダッタ
- NETRAB00122
ジェット機の シートが揺れるため 不安になる
ジェットキノ シートガユレルタメ フアンニナル
- NETRAB00123
プロジェクトは 順調に 進んでいった
プロジェクトワ ジュンチョーニ ススンデイッタ
- NETRAB00124
旅館の四階には 空き部屋と 娯楽室がある
リョカンノヨンカイニワ アキベヤト ゴラクシツガアル
- NETRAB00125
生徒会長は 静かに 不満を言い放った
セートカイチョーワ シズカニ フマンオイーハナッタ
- NETRAB00126
近頃 近所ではパトカーを よく見かける
チカゴロ キンジョデワパトカーオ ヨクミカケル
- NETRAB00127
新規プロジェクトに 早速着手する
シンキプロジェクトニ サッソクチャクシュスル
- NETRAB00128
冬になると 暗くなるのが早くなる
フユニナルト クラクナルノガハヤクナル
- NETRAB00129
薬局で お目当ての健康グッズを 購入した
ヤッキョクデ オメアテノケッコーグッズオ コーニューシタ
- NETRAB00130
ヴィンセントは 彼の作品に出てくる 空想の人物だ
ヴィンセントワ カレノサクヒンニデテクル クーソーノジンブツダ
- NETRAB00131
次の旅行で ヴェルサイユ宮殿を 見に行く
ツギノリョコーデ ヴェルサイユキューデンオ ミニイク
- NETRAB00132
通算で ガチャガチャを 三百回も引いた
ツーサンデ ガチャガチャオ サンビャッカイモヒータ
- NETRAB00133
弾力のあるバランスボールに 乗っていた
ダンリョクノアルバランスボールニ ノッテイタ
- NETRAB00134
ケチャップから 有毒な成分が 検出された
ケチャップカラ ユードクナセーブンガ ケンシュツサレタ
- NETRAB00135
薬を飲むために コップに水を注ぐ
クスリオノムタメニ コップニミズオソソグ
- NETRAB00136
ハワイとグアムに 新しいオフィスを構えた
ハワイトグアムニ アタラシーオフィスオカマエタ
- NETRAB00137
明日までに フォークダンスをマスターする
アシタマデニ フォークダンスオマスタースル
- NETRAB00138
アメリカの研究チームは 急速に 力をつけた
アメリカノケンキューチームワ キューソクニ チカラオツケタ
- NETRAB00139
ボディーガードを 過剰なぐらいまでに 引き連れる
ボディーガードオ カジョーナグライマデニ ヒキツレル
- NETRAB00140
昔から 小説家になるのが 夢であった
ムカシカラ ショーセツカニナルノガ ユメデアッタ
- NETRAB00141
観光地で 撮影した写真を シェアする
カンコーチデ サツエーシタシャシンオ シェアスル
- NETRAB00142
ウェイターに ワインだけを 注文する
ウェイターニ ワインダケオ チューモンスル
- NETRAB00143
ミスが一つもないか チェックを怠らない
ミスガヒトツモナイカ チェックオオコタラナイ
- NETRAB00144
それらの問題は 僕たちの知ったことじゃない
ソレラノモンダイワ ボクタチノシッタコトジャナイ
- NETRAB00145
出身は 東京ではなく 兵庫です
シュッシンワ トーキョーデワナク ヒョーゴデス
- NETRAB00146
老人は 声を出さずに あごで返事をした
ロージンワ コエオダサズニ オゴデヘンジオシタ
- NETRAB00147
これだけのテストでは 人間性は わかりゃしない
コレダケノテストデワ ニンゲンセーワ ワカリャシナイ
- NETRAB00148
演奏技術も 理解する能力も 一流である
エンソーギジュツモ リカイスルノーリョクモ イチリューデアル
- NETRAB00149
タヌキは むにゃむにゃと 眠ってしまった
タヌキワ ムニャムニャト ネムッテシマッタ
- NETRAB00150
テーブルに 素敵な食器が ずらりと並んだ
テーブルニ ステキナショッキガ ズラリトナランダ
- NETRAB00151
史実に基づくドラマが 近年の流行りである
シジツニモトズクドラマガ キンネンノハヤリデアル
- NETRAB00152
犯人を刺激しないように 見張りを続ける
ハンニンオシゲキシナイヨーニ ミハリオツズケル
- NETRAB00153
オンラインの ミーティング中に 子供が映る
オンラインノ ミーティングチューニ コドモガウツル
- NETRAB00154
いつの間にか ソファーの上で 眠っていた
イツノマニカ ソファーノウエデ ネムッテイタ
- NETRAB00155
寒さよりも 腹ペコの方が 僕には辛い
サムサヨリモ ハラペコノホーガ ボクニワツライ
- NETRAB00156
テーマを先に決め 関連事項を まとめる
テーマオサキニキメ カンレンジコーオ マトメル
- NETRAB00157
朝ごはんは バターをつけたパンと 一杯の紅茶
アサゴハンワ バターオツケタパント イッパイノコーチャ
- NETRAB00158
雨にうたれたので シャワーを浴び 着替えをした
アメニウタレタノデ シャワーオアビ キガエオシタ
- NETRAB00159
おでんには 大根とこんにゃくは 欠かせない
オデンニワ ダイコントコンニャクワ カカセナイ
- NETRAB00160
夕方 いろんな人たちが クリスマスツリーを見に来た
ユーガタ イロンナヒトタチガ クリスマスツリーオミニキタ
- NETRAB00161
ずいぶん偉いお客様が 紛れ込んでしまった
ズイブンエライオキャクサマガ マギレコンデシマッタ
- NETRAB00162
いまさら グズグズ言ったって 元には戻らない
イマサラ グズグズイッタッテ モトニワモドラナイ
- NETRAB00163
北海道に訪れたら じゃがバターを食べたい
ホッカイドーニオトズレタラ ジャガバターオタベタイ
- NETRAB00164
スピードが落ちて 足の限界に気付いた
スピードガオチテ アシノゲンカイニキズイタ
- NETRAB00165
彼の心の闇を 理解することはできない
カレノココロノヤミオ リカイスルコトワデキナイ
- NETRAB00166
スポーツ用のシューズを 購入する
スポーツヨーノシューズオ コーニュースル
- NETRAB00167
的を狙って ひゅっと 一本の矢を放つ
マトオネラッテ ヒュット イッポンノヤオハナツ
- NETRAB00168
一人が 一つの質問をすると 百の議論が起こる
ヒトリガ ヒトツノシツモンオスルト ヒャクノギロンガオコル
- NETRAB00169
情報は あればあるほど 強力である
ジョーホーワ アレバアルホド キョーリョクデアル
- NETRAB00170
明日は 英語でトゥモローと 発音する
アシタワ エーゴデトゥモロート ハツオンスル
- NETRAB00171
ジンジャーエールは 原材料に ショウガが含まれる
ジンジャーエールワ ゲンザイリョーニ ショーガガフクマレル
- NETRAB00172
英文は 直訳するのではなく 意訳するのが重要だ
エーブンワ チョクヤクスルノデワナク イヤクスルノガジューヨーダ
- NETRAB00173
赤いマントで 華麗に翻弄する 闘牛士
アカイマントデ カレーニホンロースル トーギューシ
- NETRAB00174
なにしろ 相手チームには 強力なディフェンダーがいる
ナニシロ アイテチームニワ キョーリョクナディフェンダーガイル
- NETRAB00175
新しい感情が 心の奥底から 湧きはじめた
アタラシーカンジョーガ ココロノオクソコカラ ワキハジメタ
- NETRAB00176
助けたキツネが 宝物のありかを 教えてくれた
タスケタキツネガ タカラモノノアリカオ オシエテクレタ
- NETRAB00177
撮影のために 遮光用のカーテンを 特注する
サツエーノタメニ シャコーヨーノカーテンオ トクチュウスル
- NETRAB00178
自分が まだまだ子供だってことを 忘れちゃいけない
ジブンガ マダマダコドモダッテコトオ ワスレチャイケナイ
- NETRAB00179
ヒンドゥー教の始まりを知るために 旅に出る
ヒンドゥーキョーノハジマリオシルタメニ タビニデル
- NETRAB00180
怪しげな銅像は 謎に満ちている
アヤシゲナドーゾーワ ナゾニミチテイル
- NETRAB00181
実験結果の表を作れば とにかく役に立つ
ジッケンケッカノヒョーオツクレバ トニカクヤクニタツ
- NETRAB00182
前方に人を集めると 横側が 手薄になる
ゼンポーニヒトオアツメルト ヨコガワガ テウスニナル
- NETRAB00183
蛇が側溝の中を ニョロニョロと這いずる
ヘビガソッコーノナカオ ニョロニョロトハイズル
- NETRAB00184
久々の筋トレで ぐっしょりと 汗をかいた
ヒサビサノキントレデ グッショリト アセオカイタ
- NETRAB00185
上の姉が 結婚する一方で 下の姉は 独身を貫く
ウエノアネガ ケッコンスルイッポーデ シタノアネワ ドクシンオツラヌク
- NETRAB00186
目覚し時計の 秒針の音が 耳につくぐらい 静かだ
メザマシドケーノ ビョーシンノオトガ ミミニツクグライ シズカダ
- NETRAB00187
余談をはさみつつ 巧妙に 探りを入れる
ヨダンオハサミツツ コーミョーニ サグリオイレル
- NETRAB00188
コミュニティーに参加して 友達を作る
コミュニティーニサンカシテ トモダチオツクル
- NETRAB00189
派手なギターのイントロが ヘッドフォンに流れた
ハデナギターノイントロガ ヘッドフォンニナガレタ
- NETRAB00190
行政にかかる費用は 増加傾向にある
ギョーセーニカカルヒヨーワ ゾーカケーコーニアル
- NETRAB00191
オブジェクト指向のプログラムを 書き始めた
オブジェクトシコーノプログラムオ カキハジメタ
- NETRAB00192
司令塔は 頭の中に チェス盤が出来上がっている
シレートーワ アタマノナカニ チェスバンガデキアガッテイル
- NETRAB00193
ペルシャには とても優秀な 学者がいた
ペルシャニワ トテモユーシューナ ガクシャガイタ
- NETRAB00194
魔法使いのおばあさんは 空へと飛んでいった
マホーツカイノオバーサンワ ソラエトトンデイッタ
- NETRAB00195
ウィーンから 急行でも 半日はかかる距離だ
ウィーンカラ キューコーデモ ハンニチワカカルキョリダ
- NETRAB00196
先輩の前では 建前と本音を ごっちゃにしない
センパイノマエデワ タテマエトホンネオ ゴッチャニシナイ
- NETRAB00197
大阪に 滞在していた時の 夜ご飯を 覚えておく
オーサカニ タイザイシテイタトキノ ヨルゴハンオ オボエテオク
- NETRAB00198
子供の時に比べて テーブルが低く感じる
コドモノトキニクラベテ テーブルガヒククカンジル
- NETRAB00199
ウォール街と ブロードウェイの位置関係を 調べる
ウォールガイト ブロードウェイノイチカンケーオ シラベル
- NETRAB00200
外国の友達に フィリピンとブラジルの ハーフがいる
ガイコクノトモダチニ フィリピントブラジルノ ハーフガイル
- NETRAB00201
イタリアのフィレンツェに 親戚がいる
イタリアノフィレンツェニ シンセキガイル
- NETRAB00202
親友に限って 嘘や偽りはない
シンユーニカギッテ ウソヤイツワリワナイ
- NETRAB00203
龍は 守り神として たてまつられている
リューワ マモリガミトシテ タテマツラレテイル
- NETRAB00204
アイデアに行き詰ったら 逆転の発想をしてみる
アイデアニイキズマッタラ ギャクテンノハッソーオシテミル
- NETRAB00205
考えてみれば 報告できるだけの 進捗はあった
カンガエテミレバ ホーコクデキルダケノ シンチョクワアッタ
- NETRAB00206
ビートルズのイェスタデイは 有名な楽曲だ
ビートルズノイェスタデイハ ユーメーナガッキョクダ
- NETRAB00207
プリンのふにゃふにゃ感を 再現する
プリンノフニャフニャカンオ サイゲンスル
- NETRAB00208
少女は ロープを基に 出発地点に戻った
ショージョワ ロープオモトニ シュッパツチテンニモドッタ
- NETRAB00209
武士としての誇りを持ち 切腹を決心した
ブシトシテノホコリオモチ セップクオケッシンシタ
- NETRAB00210
エディターにとって 編集用ソフトは 命と同じだ
エディターニトッテ ヘンシューソフトワ イノチトオナジダ
- NETRAB00211
あと五人で ちょうど二十人になる
アトゴニンデ チョードニジューニンニナル
- NETRAB00212
山脈を超えるかどうかを 熟考する
サンミャクオコエルカドーカ ジュクコースル
- NETRAB00213
ヘビのような 鋭い目を こちらに向けてきた
ヘビノヨーナ スルドイメオ コチラニムケテキタ
- NETRAB00214
直後の記述から 該当者は 妹だった
チョクゴノキジュツカラ ガイトーシャワ イモートダッタ
- NETRAB00215
後ろの席から ジェニファーが 質問に答えた
ウシロノセキカラ ジェニファーガ シツモンニコタエタ
- NETRAB00216
この神社は この田舎にとって 参拝の中心地となった
コノジンジャワ コノイナカニトッテ サンパイノチューシンチトナッタ
- NETRAB00217
多くの場合 カーブには 大きな特徴がある
オークノバーイ カーブニワ オーキナトクチョーガアル
- NETRAB00218
楽曲の提供者も 作詞に協力する
ガッキョクノテーキョーシャモ サクシニキョーリョクスル
- NETRAB00219
ムーンウォークを ひたむきに練習する
ムーンウォークオ ヒタムキニレンシュースル
- NETRAB00220
後輩の相談に乗るのも 上司の務めだ
コーハイノソーダンニノルノモ ジョーシノツトメダ
- NETRAB00221
腎臓は 老廃物をろ過する 大事な臓器だ
ジンゾーワ ローハイブツオロカスル ダイジナゾーキダ
- NETRAB00222
思い出は 知らないうちに 美化された
オモイデワ シラナイウチニ ビカサレタ
- NETRAB00223
恩返しだと思って 全国大会で 優勝する
オンガエシダトオモッテ ゼンコクタイカイデ ユーショースル
- NETRAB00224
青年は 悔しさのあまり 涙をいっぱい浮かべた
セーネンワ クヤシサノアマリ ナミダオイッパイウカベタ
- NETRAB00225
河原で 水槽に入れる小石を 百個拾う
カワラデ スイソーニイレルコイシオ ヒャッコヒロウ
- NETRAB00226
近所の仔犬は 妙に人懐っこく 足元に寄ってくる
キンジョノコイヌワ ミョーニヒトナツッコク アシモトニヨッテクル
- NETRAB00227
書物に 誤字や脱字がないか 入念に チェックする
ショモツニ ゴジヤダツジガナイカ ニューネンニ チェックスル
- NETRAB00228
それは 私たちにとって 最高の刺激となった
ソレワ ワタシタチニトッテ サイコーノシゲキトナッタ
- NETRAB00229
ピンチの時こそ 真価が問われる
ピンチノトキコソ シンカガトワレル
- NETRAB00230
復讐は 悲しみの連鎖しか生まない
フクシューワ カナシミノレンサシカウマナイ
- NETRAB00231
焼くと プリっプリなソーセージを 食べる
ヤクト プリップリナソーセージオ タベル
- NETRAB00232
おそらく 父親は ボランティア活動を始めた
オソラク チチオヤワ ボランティアカツドーオハジメタ
- NETRAB00233
子猫たちが 可愛らしく にゃーにゃーと鳴く
コネコタチガ カワイラシク ニャーニャートナク
- NETRAB00234
気迫のこもった ヘッドスライディングで 鼓舞した
キハクノコモッタ ヘッドスライディングデ コブシタ
- NETRAB00235
謝っているつもりだが むしろ相手を怒らせた
アヤマッテイルツモリダガ ムシロアイテオオコラセタ
- NETRAB00236
他のロボットも 充電をし始める
ホカノロボットモ ジューデンオシハジメル
- NETRAB00237
自分は悪くないと言わんばかりに 頭を横に振った
ジブンワワルクナイトイワンバカリニ アタマオヨコニフッタ
- NETRAB00238
熱狂的なファンによって 今回のライブも 盛り上がる
ネッキョーテキナファンニヨッテ コンカイノライブモ モリアガル
- NETRAB00239
改善はされたが 非常に 時間がかかった
カイゼンワサレタガ ヒジョウニ ジカンガカッタ
- NETRAB00240
金環日食は 実に 二十五年ぶりだった
キンカンニッショクワ ジツニ ニジューゴネンブリデアッタ
- NETRAB00241
彼の足の速さは まるで 牛歩のようだ
カレノアシノハヤサワ マルデ ギューホノヨーダ
- NETRAB00242
講義中に 疑問が一つ 解決した
コーギチューニ ギモンガヒトツ カイケツシタ
- NETRAB00243
兵庫の おすすめスポットを調べて 紹介する
ヒョーゴノ オススメスポットオシラベテ ショーカイスル
- NETRAB00244
批評家は ほとんど例外なしに ものを言う
ヒヒョーカワ ホトンドレーガイナシニ モノオユー
- NETRAB00245
インターフェイスは 使用者目線で 決めるべきである
インターフェースワ シヨーシャメセンデ キメルベキデアル
- NETRAB00246
客観的にも 主観的にも 根拠がある
キャッカンテキニモ シュカンテキニモ コンキョガアル
- NETRAB00247
とりあえず ベッドで休んでから 考えることにする
トリアエズ ベッドデヤスンデカラ カンガエルコトニスル
- NETRAB00248
今日の夜は 木村さんの家に 泊めてもらう
キョーノヨルワ キムラサンノイエニ トメテモラウ
- NETRAB00249
グラフィックに力を入れた アプリケーション
グラフィックニチカラオイレタ アプリケーション
- NETRAB00250
驚きのあまり 少女はぽかーんと 口をあけた
オドロキノアマリ ショージョワポカーント クチオアケタ
- NETRAB00251
窓口に設置されている インターフォンを押す
マドグチニセッチサレテイル インターフォンオオス
- NETRAB00252
冗談や 悪ふざけのレベルを 超えてしまっている
ジョーダンヤ ワルフザケノレベルオ コエテシマッテイル
- NETRAB00253
綺麗な星空は まるでお花畑のようだった
キレーナホシゾラワ マルデオハナバタケノヨーダッタ
- NETRAB00254
アメリカは 十三個の州から始まった
アメリカワ ジューサンコノシューカラハジマッタ
- NETRAB00255
僕のザリガニは 友達のよりも 動きが早い
ボクノザリガニワ トモダチノヨリモ ウゴキガハヤイ
- NETRAB00256
絵描きになるために 専門店で 画材を買う
エカキニナルタメニ センモンテンデ ガザイオカウ
- NETRAB00257
文脈からして 主人公は 難病を患っている
ブンミャクカラシテ シュジンコーワ ナンビョーオワズラッテイル
- NETRAB00258
柔道で えりの後ろ側を 素早く掴む
ジュードーデ エリノウシロガワオ スバヤクツカム
- NETRAB00259
粘土で作ったアルパカが ぺちゃんこに潰れた
ネンドデツクッタアルパカガ ペチャンコニツブレタ
- NETRAB00260
ゲームで敵をたくさん倒し ドロップ品を集める
ゲームデテキオタクサンタオシ ドロップヒンオアツメル
- NETRAB00261
おしゃべりな人がいると 静寂がなくて 安心する
オシャベリナヒトガイルト セージャクガナクテ アンシンスル
- NETRAB00262
山のてっぺんに着いたら やまびこをするのが定番だ
ヤマノテッペンニツイタラ ヤマビコオスルノガテーバンダ
- NETRAB00263
外国の高級車を 趣味で鑑賞する
ガイコクノコーキューシャオ シュミデカンショースル
- NETRAB00264
男性は 女性の手を堅く握って リードする
ダンセーワ ジョセーノテオカタクニギッテ リードスル
- NETRAB00265
勲章のバッジを 人形の胸元につける
クンショーノバッジオ ニンギョーノムナモトニツケル
- NETRAB00266
こちらの方角は カメラマンにとって 逆光だ
コチラノホーガクワ カメラマンニトッテ ギャッコーダ
- NETRAB00267
ピンチになったら ストッパーとして 代わってあげる
ピンチニナッタラ ストッパートシテ カワッテアゲル
- NETRAB00268
梅干をテーマにした落語が とても面白い
ウメボシヲテーマニシタラクゴガ トテモオモシロイ
- NETRAB00269
ペットのセキセイインコは お利口であった
ペットノセキセーインコワ オリコーデアッタ
- NETRAB00270
事の途中で 仲間割れは よくある出来事だ
コトノトチューデ ナカマワレワ ヨクアルコトダ
- NETRAB00271
二人のおじいさんのうち 片方が近づいてくる
フタリノオジーサンノウチ カタホーガチカズイテクル
- NETRAB00272
目を大きくして 展示されたツボを 凝視する
メオオーキクシテ テンジサレタツボオ ギョーシスル
- NETRAB00273
主人は 執事やメイドに対して とても優しい
シュジンワ シツジヤメイドニタイシテ トテモヤサシー
- NETRAB00274
絵画の少年は 鳥の姿を じっと眺めている
カイガノショーネンワ トリノスガタオ ジットナガメテイル
- NETRAB00275
強火で焼くと 豚肉が焦げてしまう
ツヨビデヤクト ブタニクガコゲテシマウ
- NETRAB00276
パンに含まれる 細菌の量を 検出する
パンニフクマレル サイキンノリョーオ ケンシュツスル
- NETRAB00277
旅人は 北の地平線を じっと見つめる
タビビトワ キタノチヘーセンオ ジットミツメル
- NETRAB00278
逆境の中 闘志だけは まだ燃え尽きない
ギャッキョーノナカ トーシダケワ マダモエツキナイ
- NETRAB00279
後ろの鏡から ジョーカーの位置が 丸見えだった
ウシロノカガミカラ ジョーカーノイチガ マルミエダッタ
- NETRAB00280
強烈なスポットライトが 演者を照らす
キョーレツナスポットライトガ エンジャオテラス
- NETRAB00281
遠目ではあったが 奇抜な帽子に 印象づけられた
トーメデワアッタガ キバツナボーシニ インショーズケラレタ
- NETRAB00282
父親は 僕の頭をなでながら 褒めちぎった
チチオヤワ ボクノアタマオナデナガラ ホメチギッタ
- NETRAB00283
危ない仕事には 手を出してはいけない
アブナイシゴトニワ テオダシテワイケナイ
- NETRAB00284
プレゼン資料のフォントは 統一させる
プレゼンシリョーノフォントワ トーイツサセル
- NETRAB00285
今年度のオールスターが 勢ぞろいだ
コンネンドノオールスターガ セーゾロイダ
- NETRAB00286
特別に 入場料は 二十円に まけてあげる
トクベツニ ニュージョーリョーワ ニジューエンニ マケテアゲル
- NETRAB00287
春夏秋冬において どれも美しい景色だった
シュンカシュートーニオイテ ドレモウツクシーケシキダッタ
- NETRAB00288
新聞の取材陣が 来訪する予定である
シンブンノシュザイジンガ ライホースルヨテーデアル
- NETRAB00289
二人は 廊下の隅で しゃがみこんだ
フタリワ ローカノスミデ シャガミコンダ
- NETRAB00290
サーファーは 水を蹴散らし 砂浜にあがって来た
サーファーワ ミズオケチラシ スナハマニアガッテキタ
- NETRAB00291
引戸が半分 開けっ放しにしてあった
ヒキドガハンブン アケッパナシニシテアッタ
- NETRAB00292
オープンキャンパスの参加手続きを 済ませる
オープンキャンパスノサンカテツズキオ スマセル
- NETRAB00293
汽車の料金表を 再度確認する
キシャノリョーキンヒョーオ サイドカクニンスル
- NETRAB00294
スプーンをこねくりまわして 余計な部分を落とす
スプーンオコネクリマワシテ ヨケーナブブンオオトス
- NETRAB00295
遠征する選手に 優しいエールを送る
エンセースルセンシュニ ヤサシーエールオオクル
- NETRAB00296
手抜きをせずに 下書きを きっちりと完成させる
テヌキオセズニ シタガキオ キッチリトカンセーサセル
- NETRAB00297
施設の管理人に 運んでもらうしか 方法がない
シセツノカンリニンニ ハコンデモラウシカ ホーホーガナイ
- NETRAB00298
まるで風のように 光のように 走り逃げた
マルデカゼノヨーニ ヒカリノヨーニ ハシリニゲタ
- NETRAB00299
オックスフォード大学の教授が 宿に泊まった
オックスフォードダイガクノキョージュガ ヤドニトマッタ
- NETRAB00300
理由はどうあれ 悪いことには変わらない
リユーワドーアレ ワルイコトニワカワラナイ
- NETRAB00301
ミルクは酸っぱくなり バターは悪臭を放つ
ミルクワスッパクナリ バターワアクシューオハナツ
- NETRAB00302
ヨークシャテリアの 毛の手入れは 時間がかかる
ヨークシャーテリアノ ケノテイレワ ジカンガカカル
- NETRAB00303
今日の勤務時間は 一時間ほどであった
キョーノキンムジカンワ イチジカンホドデアッタ
- NETRAB00304
内臓が飛び出そうなくらい 必死に走った
ナイゾーガトビデソーナクライ ヒッシニハシッタ
- NETRAB00305
ドロップアウトしても 諦めずに 何度も挑戦する
ドロップアウトシテモ アキラメズニ ナンドモチョーセンスル
- NETRAB00306
あいにく コーヒーは 一日一杯まで
アイニク コーヒーワ イチニチイッパイマデ
- NETRAB00307
宮殿には 万が一の備えが 存在する
キューデンニワ マンガイチノソナエガ ソンザイスル
- NETRAB00308
ラブソングは 何種類も 用意してきた
ラブソングワ ナンシュルイモ ヨーイシテキタ
- NETRAB00309
大臣は 優秀な学歴を持つ者たちで 構成された
ダイジンワ ユーシューナガクレキオモツモノタチデ コーセーサレタ
- NETRAB00310
学校教育は ちゃんと受けないと 損をする
ガッコーキョーイクワ チャントウケナイト ソンオスル
- NETRAB00311
このゲームの醍醐味は フィールド探索である
コノゲームノダイゴミワ フィールドタンサクデアル
- NETRAB00312
ヘリコプターは 宙に浮いて じっとしている
ヘリコプターワ チューニウイテ ジットシテイル
- NETRAB00313
鉄壁とうたわれた 城壁の跡地を 調査する
テッペキトウタワレタ ジョーヘキノアトチオ チョーサスル
- NETRAB00314
車掌に 学生証の提示を 求められる
シャショーニ ガクセーショーノテージオ モトメラレル
- NETRAB00315
助手が また一つ ピンっとひらめいた
ジョシュガ マタヒトツ ピンットヒラメイタ
- NETRAB00316
分析の結果は 予想と真逆のものであった
ブンセキノケッカワ ヨソートマギャクノモノデアッタ
- NETRAB00317
無人島では 手に入るものが 限られている
ムジントーデワ テニハイルモノガ カギラレテイル
- NETRAB00318
彼女が嘘をついていると 心の中で叫んだ
カノジョガウソオツイテイルト ココロノナカデサケンダ
- NETRAB00319
小さな写真の側に 生け花を飾る
チーサナシャシンノソバニ イケバナオカザル
- NETRAB00320
容疑者として 一時的に 拘束された
ヨーギシャトシテ イチジテキニ コーソクサレタ
- NETRAB00321
ふわっふわなシフォンケーキを 作ってあげる
フワッフワナシフォンケーキオ ツクッテアゲル
- NETRAB00322
ピエロは 格好からして 遠くからでも分かる
ピエロワ カッコーカラシテ トークカラデモワカル
- NETRAB00323
特別に注文した 巨大なステンドグラス
トクベツニチューモンシタ キョダイナステンドグラス
- NETRAB00324
古着屋の店主は びっくりしたような 様子だった
フルギヤノテンシュワ ビックリシタヨーナ ヨースダッタ
- NETRAB00325
フィンランドは 北欧の国の 一部である
フィンランドワ ホクオーノクニノ イチブデアル
- NETRAB00326
無意識にやったハミングが そこそこうけた
ムイシキニヤッタハミングガ ソコソコウケタ
- NETRAB00327
小説の冒頭で 最後のオチが 予想できる
ショーセツノボートーデ サイゴノオチガ ヨソーデキル
- NETRAB00328
弓道は 一連のしょさに 重きをおく
キュードーワ イチレンノショサニ オモキオオク
- NETRAB00329
豚の赤ちゃんは とっても可愛らしかった
ブタノアカチャンワ トッテモカワイラシカッタ
- NETRAB00330
聞き覚えのある 琵琶のねいろに 耳を澄ます
キキオボエノアル ビワノネイロニ ミミオスマス
- NETRAB00331
よそ者は ただちに村から 出ていってしまった
ヨソモノワ タダチニムラカラ デテイッテシマッタ
- NETRAB00332
階段下の居間を 覗いていた
カイダンシタノイマオ ノゾイテイタ
- NETRAB00333
男らしいあごひげを 剃らずに伸ばす
オトコラシーオアゴヒゲオ ソラズニノバス
- NETRAB00334
両側には 赤いツツジの花が 並んでいる
リョーガワニワ アカイツツジノハナガ ナランデイル
- NETRAB00335
アイスクリームを 購入せずにはいられない
アイスクリームオ コーニューセズニワイラレナイ
- NETRAB00336
寒そうだったので 優しく抱きしめてあげる
サムソーダッタノデ ヤサシクダキシメテアゲル
- NETRAB00337
東京に 九歳の娘と嫁を 置いてきた
トーキョーニ キューサイノムスメトヨメオ オイテキタ
- NETRAB00338
赤ちゃんは 事あるごとに 大声で泣き叫ぶ
アカチャンワ コトアルゴトニ オーゴエデナキサケブ
- NETRAB00339
歴史の歯車は 常に回転し続けている
レキシノハグルマワ ツネニカンテンシツズケテイル
- NETRAB00340
ほほ笑む顔の裏には 憎悪であふれていた
ホホエムカオノウラニワ ゾーオデアフレテイタ
- NETRAB00341
幸いにも 本屋は 火事に巻き込まれなかった
サイワイニモ ホンヤワ カジニマキコマレナカッタ
- NETRAB00342
一月一日は 正月で 元日と呼ばれる
イチガツツイタチワ ショーガツデ ガンジツトヨバレル
- NETRAB00343
英雄にふさわしくない 言動であった
エーユーニフサワシクナイ ゲンドーデアッタ
- NETRAB00344
人気過ぎて 初版があっという間に 売り切れた
ニンキスギテ ショハンガアットユーマニ ウリキレタ
- NETRAB00345
複数のファンが回るほど パソコンが熱くなる
フクスウノファンガマワルホド パソコンガアツクナル
- NETRAB00346
ビタミンが壊れないよう 温度調節には 気を配る
ビタミンガコワレナイヨー オンドチョーセツニワ キオクバル
- NETRAB00347
しゃべるのはやめて さっさと会計を済ませる
シャベルノワヤメテ サッサトカイケーオスマセル
- NETRAB00348
修正を頼まれることは どうにも腑に落ちない
シューセーオタノマレルコトワ ドーニモフニオチナイ
- NETRAB00349
遠方から わざわざ応援に 駆けつけてくれた
エンポーカラ ワザワザオーエンニ カケツケテクレタ
- NETRAB00350
悲劇を書いたら 次に喜劇を書くのが 鉄板だった
ヒゲキオカイタラ ツギニキゲキオカクノガ テッパンダッタ
- NETRAB00351
子供たちは ガラガラヘビのおもちゃで 遊んでいた
コドモタチワ ガラガラヘビノオモチャデ アソンデイタ
- NETRAB00352
たった一人で 十キロのご飯を 食べられるはずがない
タッタヒトリデ ジュッキロノゴハンオ タベラレルハズガナイ
- NETRAB00353
夜間でも 僕だけには分かるように 目印が置いてある
ヤカンデモ ボクダケニワワカルヨーニ メジルシガオイテアル
- NETRAB00354
鬼でも 美しい音楽には 心が動かされる
オニデモ ウツクシーオンガクニワ ココロガウゴカサレル
- NETRAB00355
悪魔のささやきに 耳を傾けてはいけない
アクマノササヤキニ ミミオカタムケテワイケナイ
- NETRAB00356
ロックダウンによる 経済への影響は 大きい
ロックダウンニヨル ケーザイエノケーキョーワ オーキー
- NETRAB00357
終わらせるのは 明日でも明後日でも どっちでもいい
オワラセルノワ アシタデモアサッテデモ ドッチデモイー
- NETRAB00358
満足している顔が 誰の目にも浮かぶ
マンゾクシテイルカオガ ダレノメニモウカブ
- NETRAB00359
サプライズゲストの登場で 喜びに満ち溢れていた
サプライズゲストノトージョーデ ヨロコビニミチアフレテイタ
- NETRAB00360
ニュートンが発見した原理は 不思議に思える
ニュートンガハッケンシタゲンリワ フシギニオモエル
- NETRAB00361
飛行機には プロゴルファーが 六人も乗っていた
ヒコーキニワ プロゴルファーガ ロクニンモノッテイタ
- NETRAB00362
デザートに フルーツポンチが出て テンションがあがる
デザートニ フルーツポンチガデテ テンションガアガル
- NETRAB00363
恐れる必要はなく 積極的に 質問をする
オソレルヒツヨーワナク セッキョクテキニ シツモンオスル
- NETRAB00364
作ってくれる料理は いつも 少し物足りない
ツクッテクレルリョーリワ イツモ スコシモノタリナイ
- NETRAB00365
ツバメが 低空飛行をするので 明日は雨だ
ツバメガ テークーヒコーオスルノデ アシタワアメダ
- NETRAB00366
噴火と一緒に 落石も起きてしまった
フンカトイッショニ ラクセキモオキテシマッタ
- NETRAB00367
冬場は 水温調節を 特に気を付ける
フユバワ スイオンチョーセツオ トクニキオツケル
- NETRAB00368
町の長老は 選挙によって 選ばれる
マチノチョーローワ センキョニヨッテ エラバレル
- NETRAB00369
ここまでは マニュアルの想定と 大差はない
ココマデワ マニュアルノソーテート タイサワナイ
- NETRAB00370
新しいプリンターは カラーコピーの 機能が付く
アタラシイプリンターワ カラーコピーノ キノーガツク
- NETRAB00371
もう一回 世界中を 飛び回った
モーイッカイ セカイジューオ トビマワッタ
- NETRAB00372
民衆が怒るのは 失敗ではなく 誠意の無さだ
ミンシューガオコルノワ シッパイデワナク セーイノナサダ
- NETRAB00373
小型でおしゃれな ワニ皮の財布を買う
コガタデオシャレナ ワニガワノサイフオカウ
- NETRAB00374
仕事が終わったので 託児所に向かった
シゴトガオワッタノデ タクジジョニムカッタ
- NETRAB00375
爬虫類といえば ヘビやトカゲが 例に挙げられる
ハチュールイトイエバ ヘビヤトカゲガ レーニアゲラレル
- NETRAB00376
ちょっとの間 ドアを開けっぱなしにする
チョットノアイダ ドアオアケッパナシニスル
- NETRAB00377
花嫁は 花婿に 心底惚れている
ハナヨメワ ハナムコニ シンソコホレテイル
- NETRAB00378
冬になると 寮の料理は 鍋ばっかりだ
フユニナルト リョーノリョーリワ ナベバッカリダ
- NETRAB00379
ドレッシングを作るのに ほんの少しばかり お酒を加える
ドレッシングオツクルノニ ホンノスコシバカリ オサケオクワエル
- NETRAB00380
試しに カラスミをちょびっと かじってみる
タメシニ カラスミオチョビット カジッテミル
- NETRAB00381
目的を しっかり定めないと 途中で迷う
モクテキオ シッカリサダメナイト トチューデマヨウ
- NETRAB00382
ガラス職人の おじいさんは いつも笑顔だ
ガラスショクニンノ オジーサンワ イツモエガオダ
- NETRAB00383
お湯を 少々加えて 色とりどりの 野菜を煮る
オユオ ショーショークワエテ イロトリドリノ ヤサイオニル
- NETRAB00384
洪水に備えて どの家にも 舟がある
コーズイニソナエテ ドノイエニモ フネガアル
- NETRAB00385
プロは ありったけの名人芸を 披露した
プロワ アリッタケノメージンゲーオ ヒローシタ
- NETRAB00386
働きアリの法則から 人の動かし方を学ぶ
ハタラキアリノホーソクカラ ヒトノウゴカシカタオマナブ
- NETRAB00387
気味の悪いことを ずっと呟いている
キミノワルイコトオ ズットツブヤイテイル
- NETRAB00388
かぼちゃの煮物は 我が家では とっても人気だ
カボチャノニモノワ ワガヤデワ トッテモニンキダ
- NETRAB00389
物音がしたので ゆっくりと 天井に目をやる
モノオトガシタノデ ユックリト テンジョーニメオヤル
- NETRAB00390
薬は 食後の一時間後に 摂取している
クスリワ ショクゴノイチジカンゴニ セッシュシテイル
- NETRAB00391
無力でも 勝ちたい気持ちは 誰にでもある
ムリョクデモ カチタイキモチワ ダレニデモアル
- NETRAB00392
生態系の頂点に 君臨する
セータイケーノチョーテンニ クンリンスル
- NETRAB00393
海外の書物を 翻訳する プロの方々です
カイガイノショモツオ ホンヤクスル プロノカタガタデス
- NETRAB00394
停電が起き 家の中は 暗闇に包まれた
テーデンガオキ イエノナカワ クラヤミニツツマレタ
- NETRAB00395
見るなと言われるほど 見たくなるのが 性分だ
ミルナトイワレルホド ミタクナルノガ ショーブンダ
- NETRAB00396
若いころには たくさんの無茶を したっていい
ワカイコロニワ タクサンノムチャオ シタッテイー
- NETRAB00397
応接間のソファーで 漫画を読んでいた
オーセツマノソファーデ マンガオヨンデイタ
- NETRAB00398
不本意にも 彼の言い訳に 笑ってしまった
フホンイニモ カレノイーワケニ ワラッテシマッタ
- NETRAB00399
少年は 悪いことを思いつくと にやにやと笑う
ショーネンワ ワルイコトオオモイツクト ニヤニヤトワラウ
- NETRAB00400
台風のせいで せっかくの実験が 台無しになった
タイフーノセーデ セッカクノジッケンガ ダイナシニナッタ
- NETRAB00401
泥棒が 逃げる瞬間を 社員が目撃した
ドロボーガ ニゲルシュンカンオ シャインガモクゲキシタ
- NETRAB00402
見上げると ひとつの黒い影が 横切った
ミアゲルト ヒトツノクロイカゲガ ヨコギッタ
- NETRAB00403
ポーチには 絆創膏や裁縫道具が 入っている
ポーチニワ バンソーコーヤサイホードーグガ ハイッテイル
- NETRAB00404
僕にはもったいないほど よくできた弟だ
ボクニワモッタイナイホド ヨクデキタオトートダ
- NETRAB00405
成功の理由は 別の解釈で 証明された
セーコーノリユーワ ベツノカイシャクデ ショーメーサレタ
- NETRAB00406
真夜中になると 電灯がぴかぴかと 黄色に光る
マヨナカニナルト デントーガピカピカト キーロニヒカル
- NETRAB00407
媚びるように 低い声でささやいた
コビルヨーニ ヒクイコエデササヤイタ
- NETRAB00408
トナカイとサンタが描かれた 子供の作品
トナカイトサンタガエガカレタ コドモノサクヒン
- NETRAB00409
今日は いつも以上に 第六感がはたらく
キョーワ イツモイジョーニ ダイロッカンガハタラク
- NETRAB00410
韓国や台湾の料理を 扱う専門店が増えた
カンコクヤタイワンノリョーリオ アツカウセンモンテンガフエタ
- NETRAB00411
繰り返し何度も何度も 映像を見た
クリカエシナンドモナンドモ エーゾーオミタ
- NETRAB00412
親友は とってもセンチメンタルである
シンユーハ トッテモセンチメンタルデアル
- NETRAB00413
二人して 同じ言葉を 大きな声で叫んだ
フタリシテ オナジコトバオ オーキナコエデサケンダ
- NETRAB00414
ワイキキビーチまで 送り届けてもらった
ワイキキビーチマデ オクリトドケテモラッタ
- NETRAB00415
お気に入りの居酒屋は 薄暗いけど 飯がうまい
オキニイリノイザカヤワ ウスグライケド メシガウマイ
- NETRAB00416
暑いので 水を頭からぶっかける
アツイノデ ミズオアタマカラブッカケル
- NETRAB00417
兄弟だからといって 手加減をすることは 許されない
キョーダイダカラトイッテ テカゲンオスルコトワ ユルサレナイ
- NETRAB00418
馬車で アメリカ人と 目的地に向かった
バシャデ アメリカジント モクテキチニムカッタ
- NETRAB00419
初めの内は 失敗ばかりを 繰り返していた
ハジメノウチワ シッパイバカリオ クリカエシテイタ
- NETRAB00420
そろそろアメリカでは 五時半頃になる
ソロソロアメリカデワ ゴジハンゴロニナル
- NETRAB00421
友達の庭に 一本の松の木が 植えてあった
トモダチノニワニ イッポンノマツノキガ ウエテアッタ
- NETRAB00422
我々に対して抵抗するのは いい度胸だ
ワレワレニタイシテテーコースルノワ イードキョーダ
- NETRAB00423
一人のために 明日の予定を 変更する
ヒトリノタメニ アシタノヨテーオ ヘンコースル
- NETRAB00424
意識を確認するために 両肩を揺さぶった
イシキオカクニンスルタメニ リョーカタオユサブッタ
- NETRAB00425
残り百メートルで ギアをチェンジする
ノコリヒャクメートルデ ギアオチェンジスル
- NETRAB00426
母親は まるで白い人魚のように 見えました
ハハオヤワ マルデシロイニンギョノヨーニ ミエマシタ
- NETRAB00427
フィアンセに 恥をかかせてはいけません
フィアンセニ ハジオカカセテワイケマセン
- NETRAB00428
ライブのプログラムを見て キャーキャーと騒いでいる
ライブノプログラムオミテ キャーキャートサワイデイル
- NETRAB00429
風呂敷の中から お土産を取り出す
フロシキノナカカラ オミヤゲオトリダス
- NETRAB00430
マントも一緒に ひらひらと 波を立てる
マントモイッショニ ヒラヒラト ナミヲタテル
- NETRAB00431
急に来た道を戻り 託児所に向かった
キューニキタミチオモドリ タクジジョニムカッタ
- NETRAB00432
極端に分厚いハムが 紛れ込んでいた
キョクタンニブアツイハムガ マギレコンデイタ
- NETRAB00433
プリズムを使って 虹を作る実験を行った
プリズムオツカッテ ニジオツクルジッケンオオコナッタ
- NETRAB00434
別荘に着いても 仕事の話を 全くやめない
ベッソーニツイテモ シゴトノハナシオ マッタクヤメナイ
- NETRAB00435
犯人は 懐にピストルを 隠し持っていた
ハンニンワ フトコロニピストルオ カクシモッテイタ
- NETRAB00436
天才の作品にも 時には 間違いがある
テンサイノサクヒンニモ トキニワ マチガイガアル
- NETRAB00437
この物語は 研究中に 思いついた
コノモノガタリワ ケンキューチューニ オモイツイタ
- NETRAB00438
水辺に咲く 睡蓮の花が 魅了する
ミズベニサク スイレンノハナガ ミリョースル
- NETRAB00439
カップルにとって その問題は深刻だった
カップルニトッテ ソノモンダイワシンコクダッタ
- NETRAB00440
僕の言葉に 父親はにこにことしていた
ボクノコトバニ チチオヤワニコニコシテイタ
- NETRAB00441
新入生に 部活のキャプテンたちが 勧誘する
シンニューセーニ ブカツノキャンプテンタチガ カンユースル
- NETRAB00442
鯉のエサやりは タダ同然の 料金設定であった
コイノエサヤリワ タダドーゼンノ リョーキンセッテーデアッタ
- NETRAB00443
余った時間を お気に入りのゲームに 費やした
アマッタジカンオ オキニイリノゲームニ ツイヤシタ
- NETRAB00444
その駅は 当時だと 終点になっていた
ソノエキワ トージダト シューテンニナッテイタ
- NETRAB00445
一流のシェフ達による 宴が開かれる
イチリューノシェフタチニヨル ウタゲガヒラカレル
- NETRAB00446
納豆と卵は 栄養の組み合わせが よくない
ナットートタマゴワ エーヨーノクミアワセガ ヨクナイ
- NETRAB00447
ミスをしたのは 失恋をしたからじゃない
ミスオシタノワ シツレンオシタカラジャナイ
- NETRAB00448
今できることを 精一杯頑張る
イマデキルコトオ セーイッパイガンバル
- NETRAB00449
密閉したはずなのに 屋敷の扉が ひらいている
ミッペーシタハズナノニ ヤシキノトビラガ ヒライテイル
- NETRAB00450
クリスマスには 骨付きのチキンを 用意する
クリスマスニワ ホネツキチキンオ ヨーイスル
- NETRAB00451
あの時代から 東京は すっかり変わってしまった
アノジダイカラ トーキョーワ スッカリカワッテシマッタ
- NETRAB00452
京都に来たからには お座敷遊びを 経験する
キョートニキタカラニワ オザシキアソビオ ケーケンスル
- NETRAB00453
愛が無ければ 婚約は認められない
アイガナケレバ コンヤクワミトメラレナイ
- NETRAB00454
病原体の正体を 明らかにした
ビョーゲンタイノショータイオ アキラカニシタ
- NETRAB00455
屋台に提灯が並び 鮮やかに見える
ヤタイニチョーチンガナラビ アザヤカニミエル
- NETRAB00456
おでこを 赤いチェックのハンカチで ふいた
オデコオ アカイチェックノハンカチデ フイタ
- NETRAB00457
高級な木材で作った琴は さぞかし 美しい
コーキューナモクザイデツクッタコトワ サゾカシ ウツクシー
- NETRAB00458
白いベールが似合う ウエディングドレスだった
シロイベールガニアウ ウエディングドレスダッタ
- NETRAB00459
姉の結婚式に 参加するため 故郷に戻ってきた
アネノケッコンシキニ サンカスルタメニ コキョーニモドッテキタ
- NETRAB00460
城門のつり橋が 跳ね上げられている
ジョーモンノツリバシガ ハネアゲラレテイル
- NETRAB00461
日向夏の原産地は 宮崎県である
ヒューガナツノゲンサンチワ ミヤザキケンデアル
- NETRAB00462
パリで見たスティールギターは 神わざであった
パリデミタスティールギターワ カミワザデアッタ
- NETRAB00463
茶碗から湯気が昇り 鼻の中に吸い込まれた
チャワンカラユゲガノボリ ハナノナカニスイコマレタ
- NETRAB00464
グヮバは トロピカルフルーツの一種だ
グヮバワ トロピカルフルーツノイッシュダ
- NETRAB00465
軍神の立てた計略が 見事にはまった
グンシンノタテタケーリャクガ ミゴトニハマッタ
- NETRAB00466
母親は ある芸能人に似た ポリネシア人だ
ハハオヤワ アルゲーノージンニニタ ポリネシアジンダ
- NETRAB00467
美的センスが 彼女と全く合わない
ビテキセンスガ カノジョトマッタクアワナイ
- NETRAB00468
髪の長さは 肩を超え うなじの辺りで束ねていた
カミノナガサワ カタオコエ ウナジノアタリデタバネテイタ
- NETRAB00469
少しでも失敗すれば 怒号が飛び交う
スコシデモシッパイスレバ ドゴーガトビカウ
- NETRAB00470
金を作るために 錬金術を勉強した
キンオツクルタメニ レンキンジュツオベンキョーシタ
- NETRAB00471
ブルートゥースを搭載した 小型機器
ブルートゥースオトーサイシタ コガタキキ
- NETRAB00472
へいに映る影を利用して 手招きをする
ヘイニウツルカゲオリヨーシテ テマネキオスル
- NETRAB00473
神話に メドゥーサとペルセウスが 登場する
シンワニ メドゥーサトペルセウスガ トージョースル
- NETRAB00474
慢性胃潰瘍にならないように 日々注意をする
マンセイイカイヨウニナラナイヨウニ ヒビチューイオスル
- NETRAB00475
ウィスキーをグラスに注ぎ 瓶を空にした
ウィスキーオグラスニツギ ビンオカラニシタ
- NETRAB00476
幼かったころの夢を まざまざと 思い出した
オサナカッタコロノユメオ マザマザト オモイダシタ
- NETRAB00477
キャラクター物の商品は マニアからの需要がある
キャラクターモノノショーヒンワ マニアカラノジュヨーガアル
- NETRAB00478
音を一つも立てることなく 自動ドアが開く
オトオヒトツモタテルコトナク ジドードアガヒラク
- NETRAB00479
読みにくいホームページは 嫌われてしまう
ヨミニクイホームページワ キラワレテシマウ
- NETRAB00480
飯ごうの底に へばりついた米粒を 綺麗に拭き取る
ハンゴーノソコニ ヘバリツイテイタコメツブオ キレーニフキトル
- NETRAB00481
老若男女を問わず 盛り上がることは 確実だ
ロウニャクナンニョオトワズ モリアガルコトワ カクジツダ
- NETRAB00482
カップルの 無駄な口喧嘩が あとを絶たない
カップルノ ムダナクチゲンカガ アトオタタナイ
- NETRAB00483
玄関口で 三十分ぐらい 躊躇した
ゲンカングチデ サンジュップングライ チューチョシタ
- NETRAB00484
ベルツォーニは ピラミッドの入り口を 見つけた
ベルツォーニワ ピラミッドノイリグチオ ミツケタ
- NETRAB00485
営業中の顔は 狂気の沙汰であった
エーギョーチューノカオワ キョーキノサタデアッタ
- NETRAB00486
真実を明らかにするには 途方もなく 時間がかかる
シンジツオアキラカニスルニワ トホーモナク ジカンガカカル
- NETRAB00487
予算ぎりぎりで 二日がかりの祭典を 開催した
ヨサンギリギリデ フツカガカリノサイテンオ カイサイシタ
- NETRAB00488
サツマイモが シラス台地で 栽培されている
サツマイモガ シラスダイチデ サイバイサレテイル
- NETRAB00489
おやじギャグを連発し 会場は シーンと静まる
オヤジギャグオレンパツシ カイジョーワ シーントシズマル
- NETRAB00490
詩人の パルクレイヴを 知っている人は あまりいない
シジンノ パルクレイヴオ シッテイルヒトワ アマリイナイ
- NETRAB00491
駅の前で ビラ配りのアルバイトをする
エキノマエデ ビラクバリノアルバイトオスル
- NETRAB00492
飛行機の窓から 海ばかりを見ていた
ヒコーキノマドカラ ウミバカリオミテイタ
- NETRAB00493
言われるがままに 現象を記録していく
イワレルガママニ ゲンショーオキロクシテイク
- NETRAB00494
どこからともなく 鳥が風を 切っていく
ドコカラトモナク トリガカゼオ キッテイク
- NETRAB00495
真実は 語られた事実を 真逆にしたものである
シンジツワ カタラレタジジツオ マギャクニシタモノデアル
- NETRAB00496
こんな世の中では 将来について 心配しかない
コンナヨノナカデワ ショーライニツイテ シンパイシカナイ
- NETRAB00497
虚無の心を 白い紙に例える
キョムノココロオ シロイカミニタトエル
- NETRAB00498
三日間考えても タイトルが思いつかない
ミッカカンカンガエテモ タイトルガオモイツカナイ
- NETRAB00499
疲れを癒すために 心ゆくまで 温泉につかる
ツカレオイヤスタメニ ココロユクマデ オンセンニツカル
- NETRAB00500
打ち上げの後 北の海岸に向かった
ウチアゲノアト キタノカイガンニムカッタ
- NETRAB00501
間もなく お遊戯会が 開演する
マモナク オユーギカイガ カイエンスル
- NETRAB00502
足跡には 泥水が溜まっていた
アシアトニワ ドロミズガタマッテイタ
- NETRAB00503
レクリエーションは 授業の一環だ
レクリエーションワ ジュギョーノイッカンダ
- NETRAB00504
宝石を入れたショーケースが 間もなく公開される
ホーセキオイレタショーケースガ マモナクコーカイサレル
- NETRAB00505
全員の毛布を 同時にがばっと はねあげた
ゼーインノモーフオ ドージニガバット ハネアゲタ
- NETRAB00506
オークションで勝ち取るには 莫大なお金がかかる
オークションデカチトルニワ バクダイナオカネガカカル
- NETRAB00507
儲けになるなら なんでも売ってあげる
モーケニナルナラ ナンデモウッテアゲル
- NETRAB00508
明治二十二年の 八月に書かれた日記だ
メージニジューニネンノ ハチガツニカカレタニッキダ
- NETRAB00509
機械を使えば ミスが無くなると 思っていた
キカイオツカエバ ミスガナクナルト オモッテイタ
- NETRAB00510
毒が 体中に回る前に 処置を施す
ドクガ カラダジューニマワルマエニ ショチオホドコス
- NETRAB00511
剣道で 仲間たちと 声出しを行う
ケンドーデ ナカマタチト コエダシオオコナウ
- NETRAB00512
お父さんは 終始黙って ラジオを聞いていた
オトーサンワ シューシダマッテ ラジオオキーテイタ`;

const netrab512 = () => {
    const rawList = netrab512Text.split('\n');
    const len = rawList.length / 3;
    const resp = [];
    for (let i = 0; i < len; i += 1) {
        resp.push({ key: rawList[i*3].replace('- ', ''), text: rawList[i*3+1], ruby: rawList[i*3+2] })
    }
    return resp;
};

export default netrab512;
