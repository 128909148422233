
import { I18n, Amplify, Auth } from 'aws-amplify';
import { Authenticator, CheckboxField, ThemeProvider, defaultDarkModeOverride, translations, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Accordion, AccordionSummary, AccordionDetails, AppBar, Button, Container, CssBaseline, Typography, Toolbar, IconButton, TextField, Grid, Box, InputAdornment, Input, Tooltip, Link } from '@mui/material';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import { Check as CheckIcon, ContentCopy as ContentCopyIcon, ExpandMore as ExpandMoreIcon, Logout as LogoutIcon } from '@mui/icons-material';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import ReactGA from "react-ga4";

import './App.css';
import getFreeConvMsec from './lib/getFreeConvMsec';
import useFreeConv from './lib/useFreeConv';
import getUserPurchases from './lib/getUserPurchases';
import Copyright from './components/Copyright';
import { useRef, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import disableUser from './lib/disableUser';
import PricingPage from './PricingPage';
import VoiceUploader from './components/VoiceUploader';

I18n.putVocabularies(translations);
I18n.setLanguage('ja');

I18n.putVocabularies({
  ja: {
    'Sign In': 'サインイン',
    'Sign Up': 'アカウント作成',
    'Reset password': 'パスワードをリセット ',
    'Reset Password': 'パスワードをリセット',
    'Sign Up with Google': 'Googleでサインアップ',
  },
});

const components = {
  Header() {
    return <img src={`${process.env.PUBLIC_URL}/head.png`} alt="Logo" style={{ width: '100%', padding: '10%' }} onClick={() => { window.location.href = 'https://suaralive.com/' }} />;
  },
  SignUp: {
    FormFields() {
      const { validationErrors } = useAuthenticator();

      return (
        <>
          {/* Re-use default `Authenticator.SignUp.FormFields` */}
          <Authenticator.SignUp.FormFields />

          {/* Append & require Terms & Conditions field to sign up  */}
          <CheckboxField
            errorMessage={validationErrors.acknowledgement as string}
            hasError={!!validationErrors.acknowledgement}
            name="acknowledgement"
            value="yes"
            label={<>
              <a href="https://suaralive.com/terms" target='_blank'>利用規約</a>
              と
              <a href="https://suaralive.com/privacy-policy" target='_blank'>プライバシーポリシー</a>
              に同意します
            </>}
          />
        </>
      );
    },
  },
}

const formFields = {
  signIn: {
    username: {
      placeholder: 'メールアドレスを入力',
      isRequired: true,
      label: 'メールアドレス'
    },
    password: {
      placeholder: 'パスワードを入力',
      isRequired: true,
    },
  },
  signUp: {
    username: {
      placeholder: 'example@tarvo.co.jp',
      isRequired: true,
      label: 'メールアドレス',
    },
    password: {
      label: 'パスワード',
      placeholder: '大文字・小文字・数字を含む8文字以上で入力',
      isRequired: true,
    },
    confirm_password: {
      label: '',
      placeholder: '同じパスワードを入力',
      isRequired: true,
    },
    birthdate: {
      isRequired: true,
      label: '生年月日',
      default: '1980/1/1',
    },
    // family_name: {
    //   placeholder: '氏名（姓）を入力',
    //   isRequired: true,
    //   label: '姓',
    // },
    // given_name: {
    //   placeholder: '氏名（名）を入力',
    //   isRequired: true,
    //   label: '名',
    // },
    // middle_name: {
    //   placeholder: 'ミドルネームを入力（オプション）',
    //   isRequired: false,
    //   label: 'ミドルネーム',
    // },
  },
  resetPassword: {
    username: {
      placeholder: 'メールアドレスを入力',
      isRequired: true,
      label: 'メールアドレス'
    },
  },
}

const services = {
  async validateCustomSignUp(formData: any) {
    if (!formData.acknowledgement) {
      return {
        acknowledgement: '利用規約とプライバシーポリシーへの同意が必要です。',
      };
    }
  },
};

Amplify.configure({
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID,
  Auth: {
    // (required) only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

    // (required)- Amazon Cognito Region
    region: process.env.REACT_APP_AWS_PROJECT_REGION,

    // (optional) - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: process.env.REACT_APP_AWS_COGNITO_REGION,

    // (optional) - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,

    // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID,

    // (optional) - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // (optional) - Hosted UI configuration
    oauth: {
      domain: 'suaralive.auth.ap-northeast-1.amazoncognito.com',
      scope: [
        // 'phone',
        'email',
        // 'profile',
        'openid',
        // 'aws.cognito.signin.user.admin'
      ],
      redirectSignIn: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_REDIRECT_URL_DEV : process.env.REACT_APP_REDIRECT_URL_PRD,
      redirectSignOut: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_REDIRECT_URL_DEV : process.env.REACT_APP_REDIRECT_URL_PRD,
      clientId: process.env.REACT_APP_AWS_APP_CLIENT_ID,
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  },
});

const styles = (theme: any) => ({
  toolbar: theme.mixins.toolbar,
})

const darkTheme = {
  name: 'my-theme',
  overrides: [defaultDarkModeOverride],
};

const muiDarkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


ReactGA.initialize("G-KKDSNC6PGN");
// // ページビューイベントを処理
// ReactGA.send("pageview");

const stripeCustomerPortalUrl = process.env.NODE_ENV === 'development' ? "https://billing.stripe.com/p/login/test_fZebK80f08FX7gkdQQ" : "https://billing.stripe.com/p/login/eVadUQ0fY8fGdNe3cc";

/* eslint-disable-next-line */
const App = ({ classes }: { classes: any }) => {

  const familyNameRef = useRef<HTMLInputElement>(null);
  const middleNameRef = useRef<HTMLInputElement>(null);
  const givenNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const emailVerifyCodeRef = useRef<HTMLInputElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const currentPasswordRef = useRef<HTMLInputElement>(null);
  const [newPassword1, setNewPassword1] = useState<string>('');
  const [newPassword2, setNewPassword2] = useState<string>('');
  const [updatingUser1, setUpdatingUser1] = useState<boolean>(false);
  const [updatingUser2, setUpdatingUser2] = useState<boolean>(false);
  const [updatingUser3, setUpdatingUser3] = useState<boolean>(false);
  const [updatingUser4, setUpdatingUser4] = useState<boolean>(false);
  const [openTip, setOpenTip] = useState<boolean>(false);
  const [emailVerifyCodeFormExpanded, setEmailVerifyCodeFormExpanded] = useState<boolean>(false);
  const [isPremiumUser, setIsPremiumUser] = useState<boolean | undefined>(void 0);
  const [premiumExpire, setPremiumExpire] = useState<number | undefined>(void 0);

  // const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>): void => {
  //   setInput(e.target.value);
  // };

  const handleCloseTip = (): void => {
    setOpenTip(false);
  };

  const handleClickButton = (text: string | undefined): void => {
    setOpenTip(true);
    if (text) navigator.clipboard.writeText(text);
  };

  async function updateUser() {
    setUpdatingUser1(true);
    const user = await Auth.currentAuthenticatedUser();
    console.log(familyNameRef.current?.value, middleNameRef.current?.value, givenNameRef.current?.value, emailRef.current?.value, phoneNumberRef.current?.value);
    const result = await Auth.updateUserAttributes(user, {
      'family_name': familyNameRef.current?.value,
      'middle_name': middleNameRef.current?.value,
      'given_name': givenNameRef.current?.value,
      'email': emailRef.current?.value,
      'phone_number': phoneNumberRef.current?.value,
    });
    console.log(result);
    setUpdatingUser1(false);
    if (result === 'SUCCESS') {
      // window.alert('更新しました');
      window.alert('新しいメールアドレスに認証コードが送信されました。');
    } else {
      window.alert('メールアドレスを更新できませんでした\n\nエラーメッセージ：\n' + result);
    }
  }

  const changeNewPassword1 = (pass: string) => {
    setNewPassword1(pass);
  };

  const changeNewPassword2 = (pass: string) => {
    setNewPassword2(pass);
  };

  async function updatePassword() {
    console.log(currentPasswordRef.current?.value, newPassword1, newPassword2);
    if (currentPasswordRef.current?.value && newPassword1) {
      if (newPassword1 === newPassword2) {
        setUpdatingUser2(true);
        const user = await Auth.currentAuthenticatedUser();
        const result = await Auth.changePassword(user, currentPasswordRef.current?.value, newPassword1);
        console.log(result);
        setUpdatingUser2(false);
        if (result === 'SUCCESS') {
          window.alert('パスワードを変更しました\n新しいパスワードでサインインしてください');
          await Auth.signOut();
          window.location.reload();
        } else {
          window.alert('パスワードを変更できませんでした\n\nエラーメッセージ：\n' + result);
        }
      } else {
        window.alert('新しいパスワードが一致しません');
      }
    } else {
      window.alert('現在のパスワードと新しいパスワードを入力してください');
    }
  }

  async function verifyEmailCode() {
    if (emailVerifyCodeRef.current?.value) {
      setUpdatingUser3(true);
      const user = await Auth.currentAuthenticatedUser();
      console.log(emailVerifyCodeRef.current?.value);
      const result = await Auth.verifyUserAttributeSubmit(user, 'email', emailVerifyCodeRef.current?.value);
      console.log(result);
      setUpdatingUser3(false);
      if (result === 'SUCCESS') {
        window.alert('認証しました\n新しいメールアドレスでサインインしてください');
        await Auth.signOut();
        window.location.reload();
      } else {
        window.alert('認証できませんでした\n\nエラーメッセージ：\n' + result);
      }
    } else {
      window.alert('認証コードを入力してください');
    }
  }

  async function deleteUser(idToken: string) {
    if (window.confirm("本当にアカウントを削除しますか？\n削除したアカウントは復元できません。")) {
      setUpdatingUser4(true);
      const result = await disableUser(idToken);
      console.log(result);
      setUpdatingUser4(false);
      if (result.result) {
        window.alert('アカウントを削除しました');
        await Auth.signOut();
        window.location.reload();
      } else {
        window.alert('アカウントを削除できませんでした\n\nエラーメッセージ：\n' + result);
      }
    }
  }

  async function initUserPurchases(token: string) {
    const purchases = await getUserPurchases(token);
    const isPremiumUser = purchases.UserAttributes.find(({ Name }: { Name: string }) => Name === 'custom:is_premium_user').Value === 'true';
    const premiumExpire = Number(purchases.UserAttributes.find(({ Name }: { Name: string }) => Name === 'custom:isPremiumUser').Value);
    console.log("isPremiumUser: ", isPremiumUser, "premiumExpire: ", new Date(premiumExpire * 1000).toString());
    setIsPremiumUser(isPremiumUser === void 0 ? false : isPremiumUser);
    setPremiumExpire(premiumExpire);
  }

  const downloadWindowsApp = () => {
    ReactGA.event("accounts.suaralive.com/download/Windows/v0.5.0");
    window.open("https://suaralive.com/download");
  }

  // Password Validation
  const passwordSymbols = "!@#$%^&*()_+\\-=\\]\\[\\{\\}\\|'";
  const regex = new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9${passwordSymbols}]{8,99}$`);
  const newPassword1ValidationResult = newPassword1.length === 0 || regex.test(newPassword1) ? false : "パスワードは 8 文字以上で、大文字・小文字・数字をそれぞれ 1 文字以上含める必要があります";
  const newPassword2ValidationResult = newPassword2.length < newPassword1.length || newPassword1 === newPassword2 ? false : "パスワードが一致しません";

  return (
    <MuiThemeProvider theme={muiDarkTheme}>
      <ThemeProvider theme={darkTheme} colorMode={'dark'}>
        <Container>
          <Authenticator
            signUpAttributes={['birthdate',]}// 'family_name', 'given_name', 'middle_name',]}
            socialProviders={['google',]}
            components={components}
            formFields={formFields}
            services={services}>
            {({ signOut, user }) => {
              const idToken = user?.getSignInUserSession()?.getIdToken().getJwtToken();
              console.log(user);
              if (idToken && isPremiumUser === void 0) initUserPurchases(idToken);
              const email = user?.getSignInUserSession()?.getIdToken().payload?.email || '';
              return (
                <main>
                  <AppBar position="absolute" style={{ background: '#191919', height: '73px', padding: '8px' }}>
                    <Toolbar
                      sx={{
                        pr: '24px', // keep right padding when drawer closed
                        background: '#191919',
                      }}
                    >
                      {/* <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="open drawer"
                      onClick={toggleDrawer}
                      sx={{
                        marginRight: '36px',
                        ...(open && { display: 'none' }),
                      }}
                    >
                      <MenuIcon />
                    </IconButton> */}
                      <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ display: 'flex', mr: 1, flexGrow: 1 }}
                        onClick={() => { window.location.href = 'https://suaralive.com/' }}
                      >
                        <img src={`${process.env.PUBLIC_URL}/head.png`} alt="Logo" style={{ width: '157px' }} />
                      </Typography>
                      <IconButton color="inherit" onClick={signOut}>
                        <LogoutIcon />
                      </IconButton>
                    </Toolbar>
                  </AppBar>
                  <div className={classes.toolbar} />
                  {/* <h1>こんにちは、{user?.attributes?.family_name} {user?.attributes?.middle_name} {user?.attributes?.given_name} さん！</h1> */}
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel0a-content"
                      id="panel0a-header"
                    >
                      <Typography sx={{ fontSize: '1.5rem', color: '#c0b4b4' }}>SuaraLiveのダウンロード</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Button variant="contained" onClick={downloadWindowsApp}>Windows版をダウンロード</Button>
                      <br /><br />
                      <Link href='https://suaralive.com/' target='_blank'>オフィシャルページ</Link>&nbsp;&nbsp;
                      <Link href='https://suaralive.com/usage' target='_blank'>使い方</Link>&nbsp;&nbsp;
                      <Link href='https://suaralive.com/faq' target='_blank'>よくある質問</Link>&nbsp;&nbsp;
                      <Link href='https://suaralive.com/guideline' target='_blank'>ガイドライン</Link>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ fontSize: '1.5rem', color: '#c0b4b4' }}>メールアドレスの変更</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={3} display={'none'}>
                          <Box display="flex" sx={{ width: '100%', height: '100%' }}><Box m="auto" sx={{ width: '100%' }}>氏名</Box></Box>
                        </Grid>
                        <Grid item xs={12} md={3} display={'none'}>
                          <TextField
                            inputRef={familyNameRef}
                            fullWidth
                            label="姓"
                            defaultValue={user?.attributes?.family_name}
                          />
                        </Grid>
                        <Grid item xs={12} md={3} display={'none'}>
                          <TextField
                            inputRef={middleNameRef}
                            fullWidth
                            label="ミドルネーム"
                            defaultValue={user?.attributes?.middle_name}
                          />
                        </Grid>
                        <Grid item xs={12} md={3} display={'none'}>
                          <TextField
                            inputRef={givenNameRef}
                            fullWidth
                            label="名"
                            defaultValue={user?.attributes?.given_name}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Box display="flex" sx={{ width: '100%', height: '100%' }}><Box m="auto" sx={{ width: '100%' }}>メールアドレス</Box></Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <TextField
                            inputRef={emailRef}
                            required
                            fullWidth
                            defaultValue={user?.attributes?.email}
                            sx={{ marginBottom: '8px' }}
                            type='email'
                            disabled={isPremiumUser}
                          />
                          {/* 上記のメールアドレスを変更すると、変更後のメールアドレス宛に認証コードが届きます。認証コードを受信した後、以下の */}
                          {/* <Link onClick={() => setEmailVerifyCodeFormExpanded(true)} sx={{ cursor: 'pointer' }}>メールアドレス変更認証コードの入力</Link> */}
                          {/* フォームに認証コードを入力すると、メールアドレスが更新されます。 */}
                          新しいメールアドレスに認証コードが送信されます。認証コードを登録するとメールアドレスの変更が完了します。
                        </Grid>
                        <Grid item xs={12} md={3} display={'none'}>
                          <Box display="flex" sx={{ width: '100%', height: '100%' }}><Box m="auto" sx={{ width: '100%' }}>TEL</Box></Box>
                        </Grid>
                        <Grid item xs={12} md={9} display={'none'}>
                          <TextField
                            inputRef={phoneNumberRef}
                            fullWidth
                            defaultValue={user?.attributes?.phone_number}
                            type='tel'
                            inputProps={{ maxLength: 16, autoComplete: "off" }}
                          />
                        </Grid>
                      </Grid>
                      <br />
                      <Typography>
                        {isPremiumUser ?
                          <span style={{color: 'red'}}>サブスクリプションが有効な状態でメールアドレスを変更できません</span>
                          : 
                          <LoadingButton variant="contained" onClick={updateUser} loading={updatingUser1} loadingPosition="end" endIcon={<CheckIcon />}>認証コードを送信</LoadingButton>
                          }
                      </Typography>
                      {isPremiumUser ? <></> :
                        <>
                          <br /><br />
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                              <Box display="flex" sx={{ width: '100%', height: '100%' }}><Box m="auto" sx={{ width: '100%' }}>認証コード</Box></Box>
                            </Grid>
                            <Grid item xs={12} md={9}>
                              <TextField
                                inputRef={emailVerifyCodeRef}
                                required
                                fullWidth
                                type='text'
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 8, autoComplete: "off" }}
                                defaultValue=''
                              />
                            </Grid>
                          </Grid>
                          <br />
                          <Typography>
                            <LoadingButton variant="contained" onClick={verifyEmailCode} loading={updatingUser3} loadingPosition="end" endIcon={<CheckIcon />}>登録</LoadingButton>
                          </Typography>
                        </>
                      }
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1c-content"
                      id="panel1c-header"
                    >
                      <Typography sx={{ fontSize: '1.5rem', color: '#c0b4b4' }}>パスワードの変更</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                          <Box display="flex" sx={{ width: '100%', height: '100%' }}><Box m="auto" sx={{ width: '100%' }}>現在のパスワード</Box></Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <TextField
                            inputRef={currentPasswordRef}
                            required
                            fullWidth
                            type="password"
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Box display="flex" sx={{ width: '100%', height: '100%' }}><Box m="auto" sx={{ width: '100%' }}>新しいパスワード</Box></Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <TextField
                            placeholder='大文字・小文字・数字を含む8文字以上で入力'
                            error={newPassword1ValidationResult ? true : false}
                            color={newPassword1.length === 0 || newPassword1ValidationResult ? undefined : "success"}
                            focused={newPassword1.length === 0 || newPassword1ValidationResult ? undefined : true}
                            helperText={newPassword1ValidationResult}
                            onChange={(e) => changeNewPassword1(e.target.value)}
                            value={newPassword1}
                            required
                            fullWidth
                            type="password"
                            sx={{ marginBottom: '8px' }}
                          />
                          <TextField
                            placeholder='同じパスワードを入力'
                            error={newPassword2ValidationResult ? true : false}
                            color={newPassword2.length === 0 || newPassword2.length < newPassword1.length || newPassword2ValidationResult ? undefined : "success"}
                            focused={newPassword2.length === 0 || newPassword2.length < newPassword1.length || newPassword2ValidationResult ? undefined : true}
                            helperText={newPassword2ValidationResult}
                            onChange={(e) => changeNewPassword2(e.target.value)}
                            value={newPassword2}
                            required
                            fullWidth
                            type="password"
                          />
                        </Grid>
                      </Grid>
                      <br />
                      <Typography>
                        <LoadingButton variant="contained" onClick={updatePassword} loading={updatingUser2} loadingPosition="end" endIcon={<CheckIcon />}>変更</LoadingButton>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  {/* <Accordion expanded={emailVerifyCodeFormExpanded} onChange={() => setEmailVerifyCodeFormExpanded(!emailVerifyCodeFormExpanded)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1b-content"
                    id="panel1b-header"
                  >
                    <Typography>メールアドレス変更認証コードの入力</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                  </AccordionDetails>
                </Accordion> */}
                  {/* <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography>ユーザ固有コード</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* <Typography>
                      弊社サポート担当からユーザ固有コードの提供を依頼された場合は、以下のコードをご連絡ください。<br />
                    </Typography> 
                    <Input
                      fullWidth
                      readOnly
                      defaultValue={user?.username}
                      endAdornment={
                        <InputAdornment position="end">
                          <Tooltip
                            arrow
                            open={openTip}
                            onClose={handleCloseTip}
                            disableHoverListener
                            placement='top'
                            title='Copied!'
                          >
                            <IconButton
                              onClick={() => handleClickButton(user?.username)}
                            >
                              <ContentCopyIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      }
                    />
                  </AccordionDetails>
                </Accordion> */}
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography sx={{ fontSize: '1.5rem', color: '#c0b4b4' }}>退会</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        退会後は、SuaraLiveアプリケーションをご利用いただけなくなります。<br />
                        <LoadingButton variant="contained" onClick={() => idToken ? deleteUser(idToken) : {}} loading={updatingUser4} loadingPosition="end" endIcon={<CheckIcon />}>退会</LoadingButton>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel5a-content"
                      id="panel5a-header"
                    >
                      <Typography sx={{ fontSize: '1.5rem', color: '#c0b4b4' }}>SuaraLive Premium</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        {premiumExpire !== void 0 ? 
                          <>
                            現在のサブスクリプションの有効期限：{premiumExpire ? new Date(premiumExpire * 1000).toString() : "読み込み中..."}<br/>
                            キャンセルするまで自動更新されます<br/><br/>
                            サブスクリプションのキャンセル・再開、請求書・領収書の発行は、以下の Stripe カスタマーポータルより行えます。<br/>
                            <Link href={stripeCustomerPortalUrl + '?prefilled_email=' + email} target='_blank'>Stripe カスタマーポータル</Link>
                          </>
                          : 
                          <PricingPage userId={user?.username || ''} email={email} />}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography sx={{ fontSize: '1.5rem', color: '#c0b4b4' }}>録音</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <VoiceUploader userId={user?.username || ''} />
                    </AccordionDetails>
                  </Accordion>
                  {process.env.NODE_ENV === 'development' ?
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4a-content"
                        id="panel4a-header"
                      >
                        <Typography>Dev</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          社内向けテストコード<br />
                          <Button variant="contained" onClick={() => idToken ? getFreeConvMsec(idToken) : {}}>getFreeConvMsec</Button>
                          {/* eslint-disable-next-line */}
                          <Button onClick={() => idToken ? useFreeConv(idToken, 1000) : {}}>useFreeConv: 1000 msec</Button><br />
                          JwtToken<br />
                        </Typography>
                        <Input
                          fullWidth
                          readOnly
                          defaultValue={idToken}
                          endAdornment={
                            <InputAdornment position="end">
                              <Tooltip
                                arrow
                                open={openTip}
                                onClose={handleCloseTip}
                                disableHoverListener
                                placement='top'
                                title='Copied!'
                              >
                                <IconButton
                                  onClick={() => handleClickButton(idToken)}
                                >
                                  <ContentCopyIcon />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                    : <></>}
                </main>
              )
            }}
          </Authenticator>
          <br />
          <Copyright />
          <CssBaseline />
        </Container>
      </ThemeProvider>
    </MuiThemeProvider>
  );
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(App);
