import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Button, Grid } from '@mui/material';
import netrab512 from '../lib/netrab512';

const corpus = netrab512();

export default function CorpusViewer(props: any) {
  return (
    <Grid container>
      <Grid item xs={2}>
        <Button onClick={() => props.setCorpusID(Math.max(props.corpusID - 1, 0))}>
          &lt; 戻る
        </Button>
      </Grid>
      <Grid item xs={8}>
        <Typography align='center' variant='caption' display="block" gutterBottom>{corpus[props.corpusID].key}</Typography>
        <Typography align='center' variant='body2'>{corpus[props.corpusID].ruby}</Typography>
        <Typography align='center' variant='body1'>{corpus[props.corpusID].text}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Button onClick={() => props.setCorpusID(Math.min(props.corpusID + 1, corpus.length - 1))}>
          次へ &gt;
        </Button>
      </Grid>
    </Grid>
  );
}
