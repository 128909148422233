import JSZip from 'jszip';
import React, { useCallback, useState } from 'react';
import { Download as DownloadIcon } from '@mui/icons-material';

import { ButtonWithIcon } from './ButtonWithIcon';
import { type ConvertedVoiceType } from './ConvertedVoiceTile';

export type DownloadRecordingsButtonType = {
  convertedVoices: ConvertedVoiceType[];
};

// Obtained and edited from: https://knooto.info/javascript-dom-snippets/
const downloadUrl = (url: string, filename: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

// Obtained and edited from: https://knooto.info/javascript-dom-snippets/
const downloadBlob = (blob: Blob, filename: string) => {
  // IE, Edge
  // @ts-expect-error: For IE
  if (navigator.msSaveOrOpenBlob) {
    // @ts-expect-error: For IE
    navigator.msSaveOrOpenBlob(blob, filename);
  }
  // Otherwise
  else {
      const url = URL.createObjectURL(blob);
      downloadUrl(url, filename);
      URL.revokeObjectURL(url);
  }
};

export const DownloadRecordingsButton = ({ convertedVoices }: DownloadRecordingsButtonType) => {
  const handleButtonClick = useCallback(async () => {
    const zip = new JSZip();
    const promises = convertedVoices.map(async ({ recordedAudioBlobUrl, convertedAudioBlobUrl }, index) => {
      const directoryName = `record_${index.toString().padStart(5, '0')}`

      const recordedVoiceBlob = await fetch(recordedAudioBlobUrl).then(r => r.blob());
      zip.file(`${directoryName}/recorded.wav`, recordedVoiceBlob);

      if (convertedAudioBlobUrl) {
        const convertedVoiceBlob = await fetch(convertedAudioBlobUrl).then(r => r.blob());
        zip.file(`${directoryName}/converted.wav`, convertedVoiceBlob);
      }
    });
    await Promise.all(promises);

    // Generate and download zip file
    const blob = await zip.generateAsync({ type: 'blob' });
    downloadBlob(blob, 'voices.zip');
  }, [convertedVoices]);

  return (
    <ButtonWithIcon
      icon={<DownloadIcon />}
      text="ダウンロード"
      onClick={handleButtonClick}
    />
  );
};

