import React, { useCallback, useEffect, useRef, useState } from 'react';

import { closeMediaStream } from '../lib/recordingUtils';

export type MicrophoneVisualizerType = {
  visualize: boolean;
};

// Reference:
// - https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode/getFloatTimeDomainData
export const MicrophoneVisualizer = ({ visualize }: MicrophoneVisualizerType) => {
  const [stream, setStream] = useState<MediaStream | null>(null);
  useEffect(() => {
    const setupStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setStream(stream);
      } catch (err) {
        console.log(err);
      }
    };

    if (visualize && stream === null) {
      setupStream();
    }
    
    if (!visualize && stream !== null) {
      closeMediaStream(stream);
      setStream(null);
    }
  }, [visualize, stream]);

  const [analyser, setAnalyser] = useState<AnalyserNode | null>(null);
  useEffect(() => {
    if (stream === null) return;

    // @ts-ignore
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    analyser.smoothingTimeConstant = 0.5;
    analyser.fftSize = 32;

    const source = audioContext.createMediaStreamSource(stream);
    source.connect(analyser);

    setAnalyser(analyser);
  }, [stream]);

  const [gain, setGain] = useState(0);
  const updateAudioChunk = useCallback(() => {
    if (analyser === null) return;

    // const startTime = performance.now();

    const bufferLength = analyser.frequencyBinCount;
    const currentAudioChunk = new Float32Array(bufferLength);
    analyser.getFloatTimeDomainData(currentAudioChunk);
    // console.log(currentAudioChunk)

    const sum = currentAudioChunk.reduce(function(sum, element){
      return sum + Math.abs(element);
    }, 1e-10)
    const amplitude = sum / bufferLength;
    const gain = 20 * Math.log10(amplitude);
    const roundedGain = Math.round(gain);
    setGain(roundedGain);

    // console.log(performance.now() - startTime)
  }, [analyser]);
  useEffect(() => {
    const intervalId = setInterval(updateAudioChunk, 50);
    return () => {
      clearInterval(intervalId);
    };
  }, [updateAudioChunk]);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    if (canvasRef === null || canvasRef.current === null) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (ctx === null) return;
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.fillStyle = '#444';
    ctx.fillRect(0, 0, canvas.width * (100 + gain) / 100, canvas.height);
  }, [gain, canvasRef]);

  return (
    visualize ?
      <canvas
        className="w-full h-5"
        style={{ height: '30px' }}
        ref={canvasRef}
      />
      :
      <></>
  );
};
