import { Button } from "@mui/material";

type ButtonWithIconType = {
  icon?: any,
  text: string,
  className?: string,
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
};

export const ButtonWithIcon = ({ icon, text, onClick, className = '' }: ButtonWithIconType) => {
  return (
    <Button variant="contained" startIcon={icon && icon} onClick={onClick}>
      {text}
    </Button>
  );
};
