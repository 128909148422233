import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import { useState, useEffect } from 'react';

const mimeType = 'audio/wav';

export const useRecorder = () => {
  const [recorder, setRecorder] = useState<any>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [audioBlobUrl, setAudioBlobUrl] = useState('');

  // Set recorder
  useEffect(() => {
    if (stream === null) return;

    const initializeRecorder = async () => {
      try {
        await register(await connect());
      } catch {}
      setRecorder(new MediaRecorder(stream, { mimeType: mimeType }));
    };

    initializeRecorder();
  }, [stream]);

  // Handle recording
  useEffect(() => {
    let chunks: Blob[] = [];

    if (recorder !== null && recorder.state === 'inactive') {
      recorder.start();
      setIsRecording(true);

      recorder.ondataavailable = (e: any) => {
        chunks.push(e.data);
      };

      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: mimeType });
        chunks = [];
        const blobUrl = window.URL.createObjectURL(blob);
        console.log('Recoeded Blob URL: ', blobUrl);
        setAudioBlobUrl(blobUrl);
        setIsRecording(false);
      };
    }
  }, [recorder]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setStream(stream);
    } catch (err) {
      console.log(err);
    }
  };

  const stopRecording = () => {
    if (recorder !== null && recorder.state !== 'inactive') {
      recorder.stop();
    }
  };

  return {
    startRecording,
    stopRecording,
    audioBlobUrl,
    isRecording,
  };
};
