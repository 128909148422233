import React, { useCallback, useState } from 'react';

import { ConvertedVoiceTile, type ConvertedVoiceType } from './ConvertedVoiceTile';
import { DownloadRecordingsButton } from './DownloadRecordingsButton';
import { VoiceRecorderType, VoiceRecorder } from './VoiceRecorder';
import {
  uploadVoice,
  downloadVoice,
  useVoiceConversionWebSocket,
  type VoiceConversionWebSocketResponse,
  getCorpusVoiceDic,
} from '../lib/apiClients';
import CorpusViewer from './CorpusViewer';
import { Button } from '@mui/material';

const CORPUS_NAME = 'NETRAB512';

export const VoiceUploader = (props: any) => {
  const [convertedVoices, setConvertedVoices] = useState<ConvertedVoiceType[]>([]);
  const [corpusID, setCorpusID] = useState<Number>(0);
  const [corpusVoiceDic, setCorpusVoiceDic] = useState<any>(null);

  const onVoiceConversionDone = async (message: VoiceConversionWebSocketResponse) => {
    switch (message.message) {
      case 'finish':
        // Download converted voice
        const downloadResponse = await downloadVoice(message.voice_id);
        if (!downloadResponse.success) return;

        // Set blobUrl
        setConvertedVoices(
          prev => prev.map(item => {
            if (item.conversionId === message.id) {
              return {
                ...item,
                convertedAudioBlobUrl: downloadResponse.blobUrl,
              };
            } else {
              return { ...item };
            }
          }),
        );

        break;
      case 'received':
        // Set conversion id
        setConvertedVoices(
          prev => prev.map(item => {
            if (item.uploadedVoiceId === message.voice_id) {
              return {
                ...item,
                conversionId: message.id,
              };
            } else {
              return { ...item };
            }
          }),
        );
        break;
      default:
        break;
    }
  };
  const { sendVoice } = useVoiceConversionWebSocket({ onVoiceConversionDone });

  const addNewRecord = useCallback(async (corpusName: string, corpusID: Number, audioBlobUrl: string) => {
    // Upload voice
    const uploadVoiceResponse = await uploadVoice(props.userId, corpusName, corpusID, audioBlobUrl);
    // TODO: Add error notification
    if (uploadVoiceResponse.result === 'error') return;
    if (uploadVoiceResponse.id === undefined) return;

    // Set state
    setConvertedVoices(
      prev => [
        {
          recordedAudioBlobUrl: audioBlobUrl,
          recordedAt: new Date(),
          uploadedVoiceId: uploadVoiceResponse.id,
        },
        ...prev,
      ],
    );

    // Update corpus voice list
    const corpusVoiceDicResponse = await getCorpusVoiceDic(props.userId, corpusName);
    setCorpusVoiceDic(corpusVoiceDicResponse);
    console.log(corpusVoiceDicResponse);

    // Exec conversion
    sendVoice(uploadVoiceResponse.id, 'params');
  }, [setConvertedVoices, sendVoice]);

  React.useEffect(() => {
    (async() => {
      const corpusVoiceDicResponse = await getCorpusVoiceDic(props.userId, CORPUS_NAME);
      setCorpusVoiceDic(corpusVoiceDicResponse);
      console.log(corpusVoiceDicResponse);
    })()
  }, []);

  let NETRAB200Enabled = corpusVoiceDic !== null;
  let NETRAB512Enabled = corpusVoiceDic !== null;
  if (corpusVoiceDic) {
    for (let i = 0; i < 512; i++) {
      if (corpusVoiceDic[String(i)] === null) {
        if (i < 200) NETRAB200Enabled = false;
        NETRAB512Enabled = false;
      }
    }
  }

  return (
    <>
      <CorpusViewer
        corpusName={CORPUS_NAME}
        corpusID={corpusID}
        setCorpusID={setCorpusID}
      />
      <VoiceRecorder
        // @ts-ignore-error
        className='mb-3'
        corpusName={CORPUS_NAME}
        corpusID={corpusID}
        onRecordCompleted={addNewRecord}
      />
      <Button disabled={!NETRAB200Enabled}>NETRAB200</Button>
      <Button disabled={!NETRAB512Enabled}>NETRAB512</Button>
      <div className="max-w-5xl mx-auto">
        {convertedVoices.length > 0 &&
          <div className="flex justify-end mt-2">
            <DownloadRecordingsButton convertedVoices={convertedVoices} />
          </div>
        }

        <ul className="mt-2">
          {convertedVoices.map(convertedVoice => {
            return (
              <li
                key={convertedVoice.recordedAudioBlobUrl}
                className='my-6'
              >
                <ConvertedVoiceTile {...convertedVoice} />
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default VoiceUploader;
