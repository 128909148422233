import React, { useCallback, useEffect } from 'react';
import { MicOutlined as MicOutlinedIcon, StopOutlined as StopOutlinedIcon } from '@mui/icons-material';

import { useRecorder } from '../lib/useRecorder';
import { MicrophoneVisualizer } from './MicrophoneVisualizer';
import { Box, Button, Grid } from '@mui/material';

export type VoiceRecorderType = {
  onRecordCompleted: (corpusName: string, corpusID: Number, audioBlobUrl: string) => void;
  className?: string;
  corpusName: string;
  corpusID: Number;
};

export const VoiceRecorder = ({ onRecordCompleted, className, corpusName, corpusID }: VoiceRecorderType) => {
  const { startRecording, stopRecording, audioBlobUrl, isRecording } = useRecorder();

  // const handleClickRecordButton = useCallback(() => {
  //   if (isRecording) {
  //     stopRecording();
  //   } else {
  //     startRecording();
  //   }
  // }, [isRecording, startRecording, stopRecording]);

  const handleMouseDownRecordButton = useCallback(() => {
    if (!isRecording) {
      startRecording();
    }
  }, [isRecording, startRecording]);

  const handleMouseUpRecordButton = useCallback(() => {
    if (isRecording) {
      stopRecording();
    }
  }, [isRecording, stopRecording]);

  useEffect(() => {
    if (audioBlobUrl === '') return;
    onRecordCompleted(corpusName, corpusID, audioBlobUrl);
  }, [audioBlobUrl, onRecordCompleted]);

  return (
    <Grid container spacing={3} className={`flex space-x-4 items-center max-w-2xl mx-auto ${className && className}`}>
      <Grid item xs={12}>
        <RecordButton
          isRecording={isRecording}
          // handleClickRecordButton={handleClickRecordButton}
          handleMouseDownRecordButton={handleMouseDownRecordButton}
          handleMouseUpRecordButton={handleMouseUpRecordButton}
        />
      </Grid>
      <Grid item xs="auto" sx={{ height: '30px' }}>
        <div className="flex-grow bg-gray-300 text-center align-middle">
          <MicrophoneVisualizer visualize={isRecording} />
        </div>
      </Grid>
    </Grid>
  );
};

type RecordButtonType = {
  isRecording: boolean;
  // handleClickRecordButton: () => void;
  handleMouseDownRecordButton: () => void;
  handleMouseUpRecordButton: () => void;
};

const RecordButton = ({ isRecording, /*handleClickRecordButton,*/ handleMouseDownRecordButton, handleMouseUpRecordButton }: RecordButtonType) => {
  return (
    <Box sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex', width: '100%' }}>
      <Button
        className={`
        rounded-full pl-8 pr-10 py-3
        text-gray-50 text-md font-semibold
        duration-100
        flex space-x-2 items-center
        ${isRecording ?
            'bg-gray-500 hover:bg-gray-700'
            :
            'bg-red-500 hover:bg-red-700'
          }
      `}
        // onClick={handleClickRecordButton}
        onMouseDown={handleMouseDownRecordButton}
        onMouseUp={handleMouseUpRecordButton}
        onKeyDown={(e) => { if (e.key === ' ') handleMouseDownRecordButton(); }}
        onKeyUp={(e) => { if (e.key === ' ') handleMouseUpRecordButton(); }}
        color="error"
        variant="contained"
        autoFocus
      >
        {isRecording ? <StopOutlinedIcon /> : <MicOutlinedIcon />}
        <span>{isRecording ? '離して停止' : '押して録音'}</span>
      </Button>
    </Box>
  );
};
